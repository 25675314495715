import React, { Component } from "react";
import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import {
  Container,
  Button,
  Card,
  CardBody,
  Input,
  FormGroup,
  CardHeader,
} from "reactstrap";
import { getProductReport } from "store/actions";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Row, Col } from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb";
import ProductReportTable from "containers/product-report/ProductReportTable";
import moment from "moment";
import { debounce } from "lodash";
import Select from "react-select";
import { getTransportDropdownRequest } from "../../store/transport/saga";
import { getCompanyName } from "../../helpers/utils";
import { timestampFileName } from "helpers/utils";
import { getProductsToExcel } from "store/product/saga";

export class ProductReport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      term: "",
      currentPage: 1,
      pageSize: 10,
      startDate: null,
      transportOptions: []
    };
  }

  debouncedFetchData = debounce(term => {
    this.props.getProductReport({ term });
  }, 1000);

  componentDidMount() {
    const { getProductReport } = this.props;

    getTransportDropdownRequest().then(data => {
      if (data) {
        this.setState({
          transportOptions: data.filter(e => e.orderIds.length > 0).map(item => {

            return {
              ...item,
              id: item.id,
              label: item.title,
              value: item.id
            }
          })
        })
      }
    })

    getProductReport({});

  }

  handleOnPageChange = page => {
    const { pageSize } = this.state;
    this.props.getProductReport({
      page,
      pageSize,
    });
    this.setState({
      currentPage: page,
    });
  };

  handleOnPageSizeChange = pageSize => {
    this.props.getProductReport({
      page: 1,
      pageSize,
    });

    this.setState({
      pageSize,
    });
  };

  handleOnSearch = e => {
    const { value } = e.target;
    this.setState({
      term: value ?? "",
    });

    this.debouncedFetchData(value);
  };

  handleSearch = () => {
    const { term, startDate } = this.state;
    this.props.getProductReport({
      term: term,
      startDate: startDate
        ? moment(startDate).format("YYYY-MM-DDTHH:mm:ss.SSS")
        : null,
    });
  };

  handleExportToExcel = () => {
      getProductsToExcel(
          `${ timestampFileName() }_Product_Report.xlsx`
      ).then(()=>{
        
      });
  };

  handleDateChange = (date, name) => this.setState({ [name]: date });

  render() {
    const { pageSize, currentPage } = this.state;
    const {
      items,
      itemCount,
      pageCount,
      loading,
      totalAmount,
      totalOutstanding,
      totalPayment,
    } = this.props;

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Product Report | {getCompanyName()}</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs title="Home" breadcrumbItem="Product Report" />
            <Row>
              <Col md={12}>
                <Card className="mb-2 rounded-2">
                  <CardHeader className="bg-transparent border pb-0 pt-3">
                    <Row className="g-1">
                      <Col md={2}>
                        <div className="search-box me-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <Input
                              type="text"
                              placeholder="Quick Search ..."
                              onChange={this.handleOnSearch}
                            ></Input>
                            <i className="fas fa-search search-icon" />
                          </div>
                        </div>
                      </Col>
                      <Col md={2}>
                        <FormGroup>
                          <DatePicker
                            className="form-control"
                            selectsStart
                            name="startDate"
                            selected={this.state.startDate}
                            startDate={this.state.startDate}
                            onChange={date =>
                              this.handleDateChange(date, "startDate")
                            }
                            dateFormat="dd-MMM-yyyy"
                            placeholderText="Expiry Date"
                            autoComplete="off"
                            isClearable
                          />
                        </FormGroup>
                      </Col>
                      <Col md={2}>
                          <Button
                            type="button"
                            outline
                            color="primary"
                            className="d-none d-lg-block"
                            onClick={this.handleSearch}
                          >
                            <i className="fas fa-filter" /> Filter
                          </Button>
                      </Col>
                      <Col md={2}></Col>
                      <Col md={4}>
                        <div className="text-sm-end">
                          <Button
                            type="button"
                            color="primary"
                            outline
                            size="md"
                            onClick={this.handleExportToExcel}
                          >
                            <i className="fas fa-file-excel" /> Export to Excel
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </CardHeader>
                </Card>
                {loading ? (
                  <div className="d-flex justify-content-center">
                    <div
                      className="spinner-border text-primary m-1"
                      role="status"
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                ) : (
                  <>
                    <ProductReportTable
                      items={items}
                      itemCount={itemCount}
                      currentPage={currentPage}
                      totalPage={pageCount}
                      loading={loading}
                      defaultPageSize={pageSize}
                      onChangePage={this.handleOnPageChange}
                      onPageSizeChange={this.handleOnPageSizeChange}
                    />
                  </>
                )}
              </Col>
            </Row>
          </Container>
          <h5></h5>
        </div>
      </React.Fragment>
    );
  }
}

ProductReport.propTypes = {
  items: PropTypes.array,
  item: PropTypes.object,
  loading: PropTypes.bool,
  totalAmount: PropTypes.number,
  totalOutstanding: PropTypes.number,
  totalPayment: PropTypes.number,
  message: PropTypes.object,
  itemCount: PropTypes.number,
  pageCount: PropTypes.number,
  getProductReport: PropTypes.func,
};

const mapStateToProps = ({ productReport }) => {
  return productReport;
};

export default withRouter(
  connect(mapStateToProps, {
    getProductReport,
  })(ProductReport)
);
