import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import CreatableSelect from "react-select/creatable";
import { debounce } from "lodash";
import { addressService } from "../../services/address-service";

const CreatableAddressSelect = props => {
  const {
    name,
    placeholder,
    value,
    onChange,
    isDisabled
  } = props;

  const [ options, setOptions ] = useState([]);
  const [ loading, setLoading ] = useState(false)
  const [ term, setTerm ] = useState('');

  const debouncedSearchUser = useCallback(
    debounce(term => {
      setTerm(term);
    }, 500),
    []
  )
  
  useEffect(() => {
    setLoading(true);
    let isMounted = true;
    addressService.getPagingRequest({ term }).then(res => {
      if (res) {
        if (isMounted) {
          const { data } = res;
          setOptions(data.map(a => {
            return {
              id: a.id,
              value: a.id,
              label: a.name
            };
          }));

          setLoading(false);
        }
      }
    });

    return () => {
      isMounted = false; // Cleanup: mark the component as unmounted
    };
  }, [ term ]);

  const handleOnChange = (newValue, actionMeta) => {
    const { action } = actionMeta;
    if (action === "create-option") {
      const address = {
        name: newValue.value
      }
      
      addressService.createRequest(address).then(res =>{
        if(res){
          addressService.getByIdRequest(res).then(res =>{
            onChange({
              id: res.id,
              value: res.id,
              label: res.name,
            }, actionMeta)
          })
        }
      })
    } else {
      console.log(newValue);
      onChange(newValue, actionMeta)
    }
  }
  
  return (
    <CreatableSelect
      name={ name }
      value={ value }
      onChange={ handleOnChange }
      options={ options }
      placeholder={placeholder}
      onInputChange={ value => debouncedSearchUser(value) }
      classNamePrefix="select2-selection"
      isDisabled={isDisabled}
      isLoading={ loading }
      isClearable
    />
  );
};

CreatableAddressSelect.propTypes = {
  name: PropTypes.string,
  value: PropTypes.object,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  isDisabled: PropTypes.bool
};

export default CreatableAddressSelect;