import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { formatItemOptionLabel } from "../../helpers/utils";
import Select, { components } from "react-select";
import { getPagingProductRequest } from "../../store/product/saga";
import { getProductUomsRequest } from "../../store/product-uom/saga";
import { getDiscountPromotionProduct } from "../../store/discount-promotion/saga";
import { NIL } from "uuid";
import { debounce } from "lodash";

const customSingleValue = ({ children, data, ...props }) => {
  const { label, uniqueId } = data;
  return (
    <components.SingleValue { ...props }>
      { label } | { uniqueId }{ " " }
    </components.SingleValue>
  );
};

const ProductSelectionDropdown = props => {
  const { onSelect, isDisabled } = props;
  const [ term, setTerm ] = useState("");
  const [ productOptions, setProductOptions ] = useState([]);
  const [ loading, setLoading ] = useState(false);

  const debouncedQuickSearch = useCallback(
    debounce(term => {
      setTerm(term);
    }, 500),
    []
  );

  const handleInputChange = value => {
    debouncedQuickSearch(value);
  };

  useEffect(() => {
    let isMounted = true;
    setLoading(true);
    getPagingProductRequest({ term, page: 1, pageSize: 20 }).then(res => {
      if (res.data) {
        if (isMounted) {
          setLoading(false);
          setProductOptions(
            res.data.map((a, index) => {
              return {
                key: a.id,
                value: a.id,
                type: a.type,
                productImages: a.productImages,
                barcode: a.barcode,
                code: a.code,
                sku: a.sku,
                price: a.price,
                label: a.name
              };
            })
          );
        }
      }
    });
    
    return () => {
        isMounted = false;
      };
  }, [ term ]);

  const handleSelectChange = async (valueType, actionMeta) => {
    const { name } = actionMeta;

    if (name === "productId" && valueType) {
      const { value: productId, label, productImages, price = 0 } = valueType;

      const newEntry = {
        id: NIL,
        productId,
        label,
        productImages,
        quantity: 1,
        price,
        total: price,
        productUomId: null,
        uomId: null,
        uomOptions: [],
        uomLabel: null,
        showUomDropdown: true,
        isSelected: false,
        discount: 0
      };

      try {
        const uomResponse = await getProductUomsRequest({ productId });
        const uomOptions = uomResponse?.data?.map((uom) => ({
          id: uom.id,
          value: uom.uomId,
          label: uom.title
        })) || [];

        newEntry.uomId = uomOptions.length > 0 ? uomOptions[0] : null;
        newEntry.uomOptions = uomOptions;
        
        const discountResponse = await getDiscountPromotionProduct({ productId });
        const discounts = discountResponse.data;

        if (discounts.length > 0) {
          const { percentage = 0 } = discounts[0];
          newEntry.discount = percentage;
          const discountAmount = price * (percentage / 100);
          newEntry.total = price - discountAmount;
        } else {
          newEntry.total = price;
        }
      } catch (error) {
        newEntry.total = price;
      }

      onSelect(newEntry);
    }
  };


  return (
    <Select
      name="productId"
      value={ null }
      placeholder={ "Find by Code, Name, ..." }
      onChange={ handleSelectChange }
      options={ productOptions }
      components={ {
        SingleValue: customSingleValue
      } }
      onInputChange={ handleInputChange }
      formatOptionLabel={ formatItemOptionLabel }
      classNamePrefix="select2-selection"
      isLoading={ loading }
      isDisabled={ isDisabled }
      isClearable
    />
  );
};

ProductSelectionDropdown.propTypes = {
  onSelect: PropTypes.func.isRequired
};

export default ProductSelectionDropdown;