import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Col, FormGroup, Input, Label, Modal, Row } from "reactstrap";

const SendMessageModal = props => {
  const { title, isOpen, toggle, onSubmit} = props;
  const [ submitted, setSubmitted ] = useState(false);
  const [ item, setItem ] = useState({
    deviceToken: '',
    title: '',
    body: ''
  });

  const onOpened = () => {
    if(props.item) {
      setItem({
        ...item,
        deviceToken: props.item.deviceToken,
      })
    }else{
      setSubmitted(false);
      setItem({
        deviceToken: '',
        title: '',
        body: ''
      })
    }
  };
  
  const handleSubmit = () => {
    let isValid = item.deviceToken && item.title && item.body;
    console.log(item)
    
    if(isValid) {
      onSubmit(item)
    }
  }
  
  return (
    <Modal
      size="md"
      onOpened={onOpened}
      isOpen={isOpen}
      toggle={toggle}
      backdrop="static"
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          {title}
        </h5>
        <button
          type="button"
          onClick={toggle}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div className="modal-body">
        <Row>
          <Col>
            <FormGroup className="mb-3">
              <Label htmlFor="">Title</Label>
              <Input
                type="text"
                name="title"
                placeholder="Title"
                className={submitted && !item.title ? "is-invalid" : ""}
                value={item.title}
                onChange={e =>{
                  setItem({
                    ...item,
                    title: e.target.value
                  })
                }}
              />
              {submitted && !item.title && (
                <div className="invalid-feedback-custom">
                  Title is required.
                </div>
              )}
            </FormGroup>
            <FormGroup className="mb-3">
              <Label htmlFor="">Body</Label>
              <Input
                type="textarea"
                rows="3"
                name="body"
                placeholder="body"
                value={item.body}
                onChange={e =>{
                  setItem({
                    ...item,
                    body: e.target.value
                  })
                }}
              />

              {submitted && !item.body && (
                <div className="invalid-feedback-custom">
                  Title is required.
                </div>
              )}
            </FormGroup>
          </Col>
        </Row>
      </div>
      <div className="modal-footer">
        <Button color="primary" onClick={handleSubmit} type="submit">
          Submit
        </Button>
        <button
          type="button"
          onClick={toggle}
          className="btn btn-secondary"
          data-dismiss="modal"
        >
          Close
        </button>
      </div>
    </Modal>
  );
};

SendMessageModal.propTypes = {
  title: PropTypes.string,
  isOpen: PropTypes.bool,
  toggle: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default SendMessageModal;