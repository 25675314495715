import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { userService } from "../../services/user-service";
import Select, { components } from "react-select";
import CreatableSelect from "react-select/creatable";
import { Badge } from "reactstrap";
import { debounce } from "lodash";
import QuickCreateUserModal from "./QuickCreateUserModal";

const customSingleValue = ({
                             children,
                             data,
                             ...props
                           }) => {
  const { label, uniqueId } = data
  return <components.SingleValue { ...props }>{ label } | { uniqueId } </components.SingleValue>
}

const customSingleValueDiscount = ({ children, data, ...props }) => {
  const { label } = data
  return <components.SingleValue { ...props }>{ label }</components.SingleValue>
}

const senderFormatOptionLabel = (user, showRole, showPhoneNumber) => {
  const { label, uniqueId, roles, phoneNumber } = user;
  return (
      <div style={ { display: "flex", flexDirection: "column" } }>
        <p className={ "mb-1" }>
          <strong>{ label }</strong>
        </p>
        <p className={ "mb-0 font-size-13" }>
          <span className={ "me-2" }>ID</span>:<span className={ "ms-2" }>{ uniqueId }</span>
        </p>
        {
            showPhoneNumber &&
            <p className={ "mb-0 font-size-13" }>
            <span className={ "me-2" }>Phone</span>:<span className={ "ms-2" }>{ phoneNumber }</span>
            </p>
        }
        
        { showRole &&
            <>
              { roles && roles.length > 0 &&
                  <p className={ "mb-0 font-size-13" }>
                    <span className={ "me-2" }>Role</span>:<span className={ "ms-2" }>
            { roles.map((r, index) => {
              return <Badge color={ "primary" } key={ index } className={ "mx-1 px-2" }>{ r.name }</Badge>
            }) }
          </span>
                  </p>
              }
            </>
        }
      </div>
  )
}

const CreatableUserSelect = props => {
  const { 
    name, 
    placeholder,
    value, 
    showRole, 
    showPhoneNumber, 
    roleNames, 
    modalTitle, 
    onChange,
    isDisabled
  } = props;
  const [ loading, setLoading ] = useState(false)
  const [ userOptions, setUserOptions ] = useState([])
  const [ term, setTerm ] = useState('');
  const [ modalQuickCreate, setModalQuickCreate ] = useState(false)
  const [ item, setItem ] = useState(null);

  const debouncedSearchUser = useCallback(
      debounce(term => {
        setTerm(term);
      }, 500),
      []
  )

  useEffect(() => {
    setLoading(true);
    let isMounted = true;
    userService.getUsersRequest({ term, roleNames }).then(records => {
      if (records) {
        if (isMounted) {
          
        setUserOptions(records.map(user => {
          return {
            key: user.id,
            value: user.id,
            label: `${ user.firstName } ${ user.lastName }`,
            phoneNumber: user.phoneNumber,
            uniqueId: user.uniqueId,
            roles: user.roles,
          };
        }));

        setLoading(false);
      }}
    });
    
    return () => {
      isMounted = false; // Cleanup: mark the component as unmounted
    };
  }, [ term ]);

  const handleOnChange = (newValue, actionMeta) => {
    const { action } = actionMeta;

    if (action === "create-option") {
      setModalQuickCreate(true);
      setItem({
        firstName: null,
        lastName: null,
        phoneNumber: newValue.value
      })
    } else {
      onChange(newValue, actionMeta)
    }
  }

  const handleOnSubmit = (data) => {
    userService.quickCreateUserRequest(data)
        .then(res => {
          console.log(res);
          const newOption = {
            key: res.id,
            value: res.id,
            label: `${ res.firstName } ${ res.lastName }`,
            uniqueId: res.uniqueId,
            roles: res.roles,
          }
          onChange(newOption, {
            name
          })

          setModalQuickCreate(false);
        })
  }

  return (
      <>
        <CreatableSelect
            name={ name }
            value={ value }
            onChange={ handleOnChange }
            options={ userOptions }
            components={ {
              SingleValue: customSingleValue
            } }
            placeholder={placeholder}
            onInputChange={ value => debouncedSearchUser(value) }
            formatOptionLabel={ option => senderFormatOptionLabel(option, showRole, showPhoneNumber) }
            classNamePrefix="select2-selection"
            isDisabled={isDisabled}
            isLoading={ loading }
            isClearable
        />

        {
            item &&
            <QuickCreateUserModal
                title={ modalTitle }
                isOpen={ modalQuickCreate }
                toggle={ () => setModalQuickCreate(false) }
                item={ item }
                onSubmit={ handleOnSubmit }
            />
        }
      </>
  );
};

CreatableUserSelect.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  showRole: PropTypes.bool,
  showPhoneNumber: PropTypes.bool,
  roleNames: PropTypes.array,
  onChange: PropTypes.func
};

export default CreatableUserSelect;