import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import {
  getPagingSaleOrderInvoicePayment,
  addNewSaleOrderInvoicePayment,
  getSaleOrderInvoicePaymentById,
  updateSaleOrderInvoicePayment,
  deleteSaleOrderInvoicePayment
} from "store/actions";

import { Button, Card, CardBody, CardHeader, Col, Input, Row } from "reactstrap";
import { hasAccess } from "../../helpers/utils";
import { permission } from "../../constants/permission";
import NumberFormat from "react-number-format";
import InvoicePaymentTable from "../../containers/sale-order-invoice-payment/InvoicePaymentTable";
// from "../../containers/sale-order-invoice-payment/ModalForm";
import AttachmentForm from "containers/sale-order-invoice-payment/ModalForm"
//import InvoicePaymentForm from "containers/invoice-payment/ModalForm";
import ConfirmDeleteModal from "../../components/Common/ConfirmDeleteModal";
import { sumBy } from "lodash";

const SaleOrderInvoicePaymentTab = props => {
  const {
    getPagingSaleOrderInvoicePayment,
    addNewSaleOrderInvoicePayment,
    updateSaleOrderInvoicePayment,
    deleteSaleOrderInvoicePayment,
    getSaleOrderInvoicePaymentById,
    saleOrderInvoiceId,
    saleOrderInvoicePayment
  } = props;

  const {
    item,
    items,
    loading,
    itemCount,
    pageCount,
  } = saleOrderInvoicePayment

  const [ selectedId, setSelectedId ] = useState(null);
  const [ term, setTerm ] = useState('')
  const [ page, setPage ] = useState(1);
  const [ pageSize, setPageSize ] = useState(10);
  const [ modalEditIsOpen, setModalEditIsOpen ] = useState(false);
  const [ modalAddIsOpen, setModalAddIsOpen ] = useState(false);
  const [ modalConfirmDeleteIsOpen, setModalConfirmDeleteIsOpen ] =
      useState(false);

  const { item:invoice } = useSelector(({ saleOrderInvoice }) => saleOrderInvoice);
  
  let totalAmount = 0;
  let totalPayment = 0;
  let outstanding = 0;
  
  if (invoice) {
    totalAmount = invoice.totalAmount;
    totalPayment = sumBy(
        items.filter(e => e.paymentStatus === 2), // 2 is Confirmed
        item => {
          return item.amount;
        }
    );
    outstanding = totalAmount - totalPayment;
  }

  useEffect(() => {
    getPagingSaleOrderInvoicePayment({
      saleOrderInvoiceId,
      term,
      page, pageSize
    })
  }, [ saleOrderInvoiceId, term, page, pageSize ]);

  const handleSubmit = (data) => {
    addNewSaleOrderInvoicePayment({
      data,
      queryParams: {
        saleOrderInvoiceId,
      }
    });
    setModalAddIsOpen(false)
  }

  const handleUpdate = data => {
    updateSaleOrderInvoicePayment({
      data,
      queryParams: {
        saleOrderInvoiceId,
      }
    });

    setModalEditIsOpen(false);
  }

  const handleDelete = () => {
    deleteSaleOrderInvoicePayment({
      data: selectedId,
      queryParams: {
        saleOrderInvoiceId,
      }
    })
    setModalConfirmDeleteIsOpen(false);
  }

  const handleConfirmDelete = (id) => {
    setSelectedId(id);
    setModalConfirmDeleteIsOpen(true);
  }

  const handleOnEdit = (id) => {
    getSaleOrderInvoicePaymentById(id);
    setModalEditIsOpen(true);
  }


  return (
      <>
        <Card className="mb-2 rounded-2">
          <CardHeader className="bg-transparent border pb-1 pt-3">
            <Row className="g-1">
              <Col md={ 2 }>
                <div className="search-box me-2 mb-2 d-inline-block">
                  <div className="position-relative">
                    <Input
                        type="text"
                        placeholder="Quick Search ..."
                        // onChange={ this.handleOnSearch }
                    ></Input>
                    <i className="fas fa-search search-icon"/>
                  </div>
                </div>
              </Col>
              <Col md={ 6 }></Col>
              <Col md={ 4 }>
                <div className="text-sm-end">
                  {/* { hasAccess(permission.saleOrderInvoicePayment.read) && ( */}
                      <Button
                          style={ {
                            marginRight: "10px",
                          } }
                          type="button"
                          color="primary"
                          onClick={ () => setModalAddIsOpen(true) }
                      >
                        <i className="fas fa-plus"/> Add New
                      </Button>
                  {/* ) } */}
                  { hasAccess(permission.saleOrderInvoicePayment.export) && (
                      <Button
                          type="button"
                          color="primary"
                          outline
                          size="md"
                          onClick={ this.handleExportToExcel }
                      >
                        <i className="fas fa-file-excel"/> Export to Excel
                      </Button>
                  ) }
                </div>
              </Col>
            </Row>
          </CardHeader>
        </Card>
        <Card className="mb-2">
          <CardBody className="pb-0">
            <Row>
              <Col md={ 4 }>
                <Card
                    color="primary"
                    className="border border-primary mini-stats-wid"
                >
                  <CardBody>
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <p className="text-white fw-medium">Total Amount</p>
                        <h4 className="mb-0 text-white">
                          <NumberFormat
                              value={ Number(totalAmount ?? 0).toFixed(2) }
                              thousandSeparator={ true }
                              fixedDecimalScale={ true }
                              displayType="text"
                              prefix="$ "
                          />
                        </h4>
                      </div>
                      <div
                          className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center"
                          style={ {
                            borderColor: "white",
                            borderStyle: "solid",
                          } }
                      >
                        <span className="avatar-title">
                          <i className={ "fas fa-dollar-sign font-size-24" }/>
                        </span>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col md={ 4 }>
                <Card
                    color="success"
                    className="border border-success mini-stats-wid"
                >
                  <CardBody>
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <p className="text-white fw-medium">Total Payment</p>
                        <h4 className="mb-0 text-white">
                          <NumberFormat
                              value={ Number(totalPayment).toFixed(2) }
                              thousandSeparator={ true }
                              fixedDecimalScale={ true }
                              displayType="text"
                              prefix="$ "
                          />
                        </h4>
                      </div>
                      <div
                          className="mini-stat-icon avatar-sm rounded-circle bg-success align-self-center"
                          style={ {
                            borderColor: "white",
                            borderStyle: "solid",
                          } }
                      >
                        <span className="avatar-title bg-success">
                          <i className={ "bx bx-check-circle font-size-24" }/>
                        </span>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col md={ 4 }>
                <Card
                    color="warning"
                    className="border border-warning mini-stats-wid"
                >
                  <CardBody>
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <p className="text-white fw-medium">Balance Due</p>
                        <h4 className="mb-0 text-white">
                          <NumberFormat
                              value={ Number(outstanding).toFixed(2) }
                              thousandSeparator={ true }
                              fixedDecimalScale={ true }
                              displayType="text"
                              prefix="$ "
                          />
                        </h4>
                      </div>
                      <div
                          className="mini-stat-icon avatar-sm rounded-circle bg-success align-self-center"
                          style={ {
                            borderColor: "white",
                            borderStyle: "solid",
                          } }
                      >
                        <span className="avatar-title bg-warning">
                          <i className={ "bx bx-list-ol font-size-24" }/>
                        </span>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </CardBody>
        </Card>

        { items && (
            <InvoicePaymentTable
                items={ items }
                itemCount={ itemCount }
                currentPage={ page }
                totalPage={ pageCount }
                loading={ loading }
                defaultPageSize={ pageSize }
                onEdit={ handleOnEdit }
                onConfirmDelete={ handleConfirmDelete }
                onChangePage={ page => setPage(page) }
                onPageSizeChange={
                  (size)=>{
                    setPage(1);
                    setPageSize(size);
                  }
                }
            />
        ) }

        <AttachmentForm
            title={ "Add New Sale Order Invoice Payment" }
            saleOrderInvoiceId={ saleOrderInvoiceId }
            isOpen={ modalAddIsOpen }
            toggle={ () => setModalAddIsOpen(false) }
            onSubmit={ handleSubmit }
        />

        { item && (
            <AttachmentForm
                title={ "Edit Sale Order Invoice Payment" }
                saleOrderInvoicePayment={ item }
                totalAmount={ totalAmount }
                isOpen={ modalEditIsOpen }
                toggle={ () => setModalEditIsOpen(false) }
                onSubmit={ handleUpdate }
            />
        ) }

        <ConfirmDeleteModal
            title="Confirm Delete"
            isOpen={ modalConfirmDeleteIsOpen }
            toggle={ () => setModalConfirmDeleteIsOpen(false) }
            onSubmit={ handleDelete }
        />
      </>
  );
};

SaleOrderInvoicePaymentTab.propTypes = {
  saleOrderInvoicePayment: PropTypes.object,
  saleOrderInvoiceId: PropTypes.string,
  getPagingSaleOrderInvoicePayment: PropTypes.func,
  addNewSaleOrderInvoicePayment: PropTypes.func,
  getSaleOrderInvoicePaymentById: PropTypes.func,
  updateSaleOrderInvoicePayment: PropTypes.func,

  deleteSaleOrderInvoicePayment: PropTypes.func,
};

const mapStateToProps = ({ saleOrderInvoicePayment }) => {
  return {
    saleOrderInvoicePayment,
  };
};

export default withRouter(
    connect(mapStateToProps, {
      getPagingSaleOrderInvoicePayment,
      addNewSaleOrderInvoicePayment,
      getSaleOrderInvoicePaymentById,
      updateSaleOrderInvoicePayment,
      deleteSaleOrderInvoicePayment
    })(SaleOrderInvoicePaymentTab)
);