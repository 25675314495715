import Breadcrumbs from "components/Common/Breadcrumb";
import React, { useCallback, useEffect, useState } from "react";
import { MetaTags } from "react-meta-tags";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Card,
  CardHeader,
  Col,
  Container, 
  FormGroup,
  Input,
  Row, Button
} from "reactstrap";
import { timestampFileName, hasAccess, saleOrderInvoiceStatusOptions } from "../../helpers/utils";
import {
  getPagingSaleOrderInvoice,
  addSaleOrderInvoice,
  updateSaleOrderInvoice,
  getSaleOrderInvoiceById,
  deleteSaleOrderInvoice
} from "store/actions";
import ConfirmDeleteModal from "components/Common/ConfirmDeleteModal";
import SaleOrderInvoiceSummary from "containers/sale-order-invoice/SaleOrderInvoiceSummary";

import { saleOrderInvoiceService } from "services/sale-order-invoice-service";
import { permission } from "constants/permission";
import { debounce } from "lodash";
import SaleOrderInvoiceTable from "containers/sale-order-invoice/SaleOrderInvoiceTable";
import Select from "react-select";
import {
  saleOrderInvoiceOptions
} from "../../helpers/utils";

const SaleOrderInvoiceList = (props) => {
  const [ id, setId ] = useState(null);
  const [ term, setTerm ] = useState("");
  const [ page, setPage ] = useState(1);
  const [ pageSize, setPageSize ] = useState(10);
  const [ modalConfirmDeleteIsOpen, setModalConfirmDeleteIsOpen ] =
    useState(false);
  const [ isCollapse, setIsCollapse ] = useState(false);
  const [ statuses, setStatuses ] = useState([]);
  const [ moreActionIsOpen, setMoreActionIsOpen ] = useState(false);
  const [ mySaleOrderInvoice, setMySaleOrderInvoice ] = useState( 
    hasAccess(permission.mySaleOrderInvoice.enable) ? 
      { key: 0, value: true, label: "My Invoice" }: 
      { key: 1, value: false, label: "All Invoice" });

  const {
    getPagingSaleOrderInvoice,
    history,
    deleteSaleOrderInvoice,
    saleOrderInvoice
  } = props;

  const {
    message,
    items,
    item,
    loading,
    itemCount,
    pageCount
  } = saleOrderInvoice;

  const debouncedFetchData = useCallback(debounce(term => {
    setTerm(term);
  }, 1000), []);

  useEffect(() => {
    getPagingSaleOrderInvoice({
      page,
      pageSize,
      term,
      mySaleOrderInvoice: mySaleOrderInvoice?.value,
      statuses: statuses ? statuses.map(status => {
        return status.value;
      }) : null
    });
  }, [ page, pageSize, term, mySaleOrderInvoice, statuses ]);

  const handleOnEdit = id => {
    history.push(`/sale-order-invoice/${ id }`);
  };

  const handleExportToPdf = id => {
    if (id) {
      saleOrderInvoiceService.exportToPdf(id, `${ timestampFileName() }_sale_invoice.pdf`);
    }
  };

  const toggleMoreAction = () => {
    setMoreActionIsOpen(prevState => !prevState);
  };

  const handleExportToExcel = () => {
    saleOrderInvoiceService.exportToExcelRequest(
      {
        invoiceNo: term ?? "",
        startDate: null,
        endDate: null,
        status: statuses.length > 0 ? statuses.map(status => status.value) : null,
        saleOrderId: null,
        invoiceToUserId: null,
        note: null,
        dueDateStart: null,
        dueDateEnd: null,
        minTotalAmount: null,
        maxTotalAmount: null
      },
      `${ timestampFileName() }_SaleOrderInvoice_List.xlsx`
    );
  };

  const handleOnAddNew = () => {
    history.push("/sale-order/add-new");
  };

  const handleConfirmDelete = id => {
    setId(id);
    setModalConfirmDeleteIsOpen(true);
  };

  const handleDelete = () => {
    deleteSaleOrderInvoice({
      data: id,
      queryParams: {
        page,
        pageSize,
        term,
        mySaleOrderInvoice: mySaleOrderInvoice?.value,
        statuses: statuses ? statuses.map(status => {
          return status.value;
        }) : null
      }
    });
    setModalConfirmDeleteIsOpen(false);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Sale Order Invoice | CR TechSoft</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Sale Order Invoice" breadcrumbItem="Sale Order Invoice List" />
          <Row>
            <Col md={ 12 }>
              <Card className="mb-1 rounded-3">
                <CardHeader className="bg-transparent border pb-0 pt-3">
                  <Row className="g-1">
                    <Col md={ 2 }>
                      <div className="search-box me-2 mb-2 d-inline-block">
                        <div className="position-relative">
                          <Input
                            type="text"
                            placeholder="Quick Search ..."
                            onChange={ (e) => {
                              debouncedFetchData(e.target.value);
                            } }
                          ></Input>
                          <i className="fas fa-search search-icon" />
                        </div>
                      </div>
                    </Col>
                    { hasAccess(permission.mySaleOrderInvoice.enable) && (
                      <Col>
                        <FormGroup>
                          <Select
                            name="mySaleOrderInvoice"
                            value={ mySaleOrderInvoice }
                            onChange={ option => setMySaleOrderInvoice(option) }
                            options={ saleOrderInvoiceOptions }
                            classNamePrefix="select2-selection"
                            placeholder="Order Type"
                            isClearable
                          />
                        </FormGroup>
                      </Col>
                    )
                    }
                    <Col md={4}>
                      <FormGroup>
                        <Select
                          name="status"
                          value={ statuses }
                          onChange={ option => setStatuses(option) }
                          options={ saleOrderInvoiceStatusOptions }
                          classNamePrefix="select2-selection"
                          placeholder="Payment Status"
                          isClearable
                          isMulti
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <div className="text-sm-end">
                        <Button
                          type="button"
                          color="primary"
                          outline
                          size="md"
                          onClick={ handleExportToExcel }
                        >
                          <i className="fas fa-file-excel" /> Export to
                          Excel
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>
              </Card>
            </Col>
            <Col md={ 12 }>
              {
                loading ? <>
                    <div className="d-flex justify-content-center">
                      <div className="spinner-border text-primary m-1" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                  </> :
                  <>
                  { items && (
                      <>
                        <SaleOrderInvoiceSummary
                          saleOrderInvoice={ saleOrderInvoice }
                          title={
                            "Sale Order Invoice Status Summary"
                          }
                          // onStatusFilter={handleStatusFilter}
                        />
                        <SaleOrderInvoiceTable
                          items={ items }
                          itemCount={ itemCount }
                          page={ page }
                          totalPage={ pageCount }
                          loading={ loading }
                          defaultPageSize={ pageSize }
                          onExportToPdf={ handleExportToPdf }
                          onEdit={ handleOnEdit }
                          onConfirmDelete={ handleConfirmDelete }
                          onChangePage={ (page) => setPage(page) }
                          onPageSizeChange={ (pageSize) => {
                            setPage(1);
                            setPageSize(pageSize);
                          } }
                        />
                      </>
                    ) }
                  </>
              }

              <ConfirmDeleteModal
                title="Confirm Delete"
                isOpen={ modalConfirmDeleteIsOpen }
                toggle={ () => setModalConfirmDeleteIsOpen(!modalConfirmDeleteIsOpen) }
                onSubmit={ handleDelete }
              />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

SaleOrderInvoiceList.propTypes = {
  saleOrderInvoice: PropTypes.object,
  itemCount: PropTypes.number,
  items: PropTypes.array,
  item: PropTypes.object,
  getPagingSaleOrderInvoice: PropTypes.func,
  addSaleOrderInvoice: PropTypes.func,
  updateSaleOrderInvoice: PropTypes.func,
  getSaleOrderInvoiceById: PropTypes.func,
  deleteSaleOrderInvoice: PropTypes.func
};

const mapStateToProps = ({ saleOrderInvoice }) => {
  return {
    saleOrderInvoice
  };
};

export default withRouter(
  connect(mapStateToProps, {
    getPagingSaleOrderInvoice,
    addSaleOrderInvoice,
    updateSaleOrderInvoice,
    getSaleOrderInvoiceById,
    deleteSaleOrderInvoice
  })(SaleOrderInvoiceList)
);
