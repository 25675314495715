import React from "react";
import PropTypes from "prop-types";
import { Card, CardBody } from "reactstrap";
import NumberFormat from "react-number-format";

const InvoiceSummaryCard = props => {
  const { title, count, iconName, color } = props;
  return (
    <Card
      color={color}
      className="mini-stats-wid mt-1 mb-2 border me-1 w-100 clickable"
      // onClick={() =>
      //   onStatusFilter({
      //     invoiceIds: all,
      //     paymentStatus: null,
      //   })
      // }
    >
      <CardBody className="p-2">
        <div className="d-flex">
          <div className="flex-grow-1">
            <p className="text-white font-size-12 fw-bold">{title}</p>
            <h4 className="mb-0 text-white text-center">
              <NumberFormat
                value={count}
                displayType="text"
                thousandSeparator={true}
                fixedDecimalScale={false}
              />
            </h4>
          </div>
          <div
            className="mini-stat-icon avatar-sm rounded-circle align-self-center"
            style={{
              borderColor: "white",
              borderStyle: "solid",
            }}
          >
                        <span className="avatar-title bg-transparent">
                          <i className={`fas ${iconName} font-size-24`}></i>
                        </span>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

InvoiceSummaryCard.propTypes = {
  title: PropTypes.string,
  count: PropTypes.number,
  iconName: PropTypes.string,
  color: PropTypes.string,
};

export default InvoiceSummaryCard;