import React from "react";
import PropTypes from "prop-types";
import { Card, CardHeader, CardTitle, Col, Row } from "reactstrap";
import InvoiceSummaryCard from "./InvoiceSummaryCard";

const SaleOrderInvoiceSummary = props => {
  const { saleOrderInvoice, title } = props;
  const {
    draftCount,
    invoicedCount,
    paidCount,
    unpaidCount,
    refundCount,
    cancelledCount

  } = saleOrderInvoice || {};
  let allCount = draftCount + invoicedCount + paidCount + unpaidCount + refundCount + cancelledCount;

  return (
    <Card className="mb-0">
      <CardHeader className="bg-transparent border">
        <CardTitle>{ title }</CardTitle>
        <Row>
          <Col md={ 12 }>
            <div className="d-flex flex-row">
              <InvoiceSummaryCard
                color={ "primary" }
                title={ "All" }
                iconName={ "fa-cubes" }
                count={ allCount } />
              <InvoiceSummaryCard
                color={ "info" }
                title={ "Invoiced" }
                iconName={ "fa-list" }
                count={ invoicedCount } />

              <InvoiceSummaryCard
                color={ "success" }
                title={ "Paid" }
                iconName={ "fa-check" }
                count={ paidCount } />

              <InvoiceSummaryCard
                color={ "danger" }
                title={ "Unpaid" }
                iconName={ "fa-times" }
                count={ unpaidCount } />

              <InvoiceSummaryCard
                color={ "warning" }
                title={ "Refund" }
                iconName={ "fa-undo" }
                count={ refundCount } />

              <InvoiceSummaryCard
                color={ "dark" }
                title={ "Cancelled" }
                iconName={ "fa-ban" }
                count={ cancelledCount } />
            </div>
          </Col>
        </Row>
      </CardHeader>
    </Card>
  );
};

SaleOrderInvoiceSummary.propTypes = {
  item: PropTypes.object,
  title: PropTypes.string
};

export default SaleOrderInvoiceSummary;