import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import CustomPagination from "components/Common/CustomPagination";
import moment from "moment";
import {
  displayInvoicePaymentStatus,
  displayTransportStatus,
  hasAccess,
} from "helpers/utils";
import { permission } from "constants/permission";
import NumberFormat from "react-number-format";
import { sumBy } from 'lodash';

class ProductReportTable extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

    calculateDateDifference = (expiryDate) => {
      const now = moment(); 
      const expiration = moment(expiryDate); 
      const totalMonths = expiration.diff(now, 'months', true); 
      const fullMonths = Math.abs(Math.floor(totalMonths)); 
      const formattedExpiryDate = expiration.format('DD-MMM-YYYY');
  
      if (fullMonths > 0) {
        return `${formattedExpiryDate} (${fullMonths} month${fullMonths > 1 ? 's' : ''})`;
      } else if (fullMonths === 0) {
        return `${formattedExpiryDate} (Due this month)`;
      } else {
        return `${formattedExpiryDate} (${Math.abs(fullMonths)} month${Math.abs(fullMonths) > 1 ? 's' : ''})`;
      }
    };

  render() {
    const {
      itemCount,
      currentPage,
      totalPage,
      defaultPageSize,
      items,
      loading,
      onEdit,
      onChangePage,
      onPageSizeChange,
    } = this.props;
    return (
      <Card>
        <CardBody>
          <div className="table-rep-plugin">
            {loading ? (
              <div className="d-flex justify-content-center">
                <div className="spinner-border text-primary m-1" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              <div
                className="table-responsive mb-0"
                data-pattern="priority-columns"
              >
                <Table
                  id="tech-companies-1"
                  className="table table-striped table-bordered"
                >
                  <thead>
                    <tr>
                      <th className="text-center">#</th>
                      <th className="text-center">Barcode</th>
                      <th className="text-center">Product Name</th>
                      <th className="text-center">Expiry Date</th>
                      <th className="text-center">Total Unit</th>
                      <th className="text-center">Total Box</th>
                    </tr>
                  </thead>
                  <tbody>
                    {items.map((item, index) => {
                      let num =
                        (currentPage - 1) * defaultPageSize + (index + 1);
                      return (
                        <tr
                          key={index}
                          style={{ cursor: "pointer" }}
                        >
                          <td>{num}</td>
                          <td>
                            {item.barcode}
                          </td>
                          <td >
                            {item.name}
                          </td>
                          <td>
                            {this.calculateDateDifference(item.expiryDate)}
                          </td>
                          <td>
                            {sumBy(item.productUoms || [], a => a.onHand * a.conversionRate) || 0}
                          </td>
                          <td>
                            {sumBy(item.productUoms || [], a => a.boxCount || 0) || 0}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            )}
          </div>
          <CustomPagination
            itemCount={itemCount}
            currentPage={currentPage}
            totalPage={totalPage}
            defaultPageSize={defaultPageSize}
            pageSizeOptions={[5, 10, 20, 30, 40, 50, 100]}
            onChangePage={i => onChangePage(i)}
            onPageSizeChange={size => onPageSizeChange(size)}
          />
        </CardBody>
      </Card>
    );
  }
}

ProductReportTable.propTypes = {
  itemCount: PropTypes.number,
  currentPage: PropTypes.number,
  totalPage: PropTypes.number,
  defaultPageSize: PropTypes.number,
  items: PropTypes.array,
  loading: PropTypes.bool,
  onEdit: PropTypes.func,
  onExportToPdf: PropTypes.func,
  onConfirmDelete: PropTypes.func,
  onChangePage: PropTypes.func,
  onPageSizeChange: PropTypes.func,
};

export default ProductReportTable;
