import {
  GET_SALE_ORDER_INVOICE_REPORT,
  GET_SALE_ORDER_INVOICE_REPORT_SUCCESS,
  GET_SALE_ORDER_INVOICE_REPORT_FAIL,
  SALE_ORDER_INVOICE_REPORT_CLEAR_NOTIFY,
} from "./actionTypes";

// SaleOrderInvoiceReport
export const getSaleOrderInvoiceReport = payload => ({
  type: GET_SALE_ORDER_INVOICE_REPORT,
  payload,
});

export const getSaleOrderInvoiceReportSuccess = payload => ({
  type: GET_SALE_ORDER_INVOICE_REPORT_SUCCESS,
  payload,
});

export const getSaleOrderInvoiceReportFail = error => ({
  type: GET_SALE_ORDER_INVOICE_REPORT_FAIL,
  payload: error,
});

export const clearSaleOrderInvoiceReportNotify = () => ({
  type: SALE_ORDER_INVOICE_REPORT_CLEAR_NOTIFY,
});
