import React, { useEffect, useState } from 'react';
import { NIL, NIL as NIL_UUID } from "uuid";
import {
  Alert,
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  FormGroup,
  Input,
  Label,
  Row
} from "reactstrap";
import Select, { components } from "react-select";
import CreatableSelect from "react-select/creatable";
import DatePicker from "react-datepicker";
import NumberFormat from "react-number-format";
import OrderItemRow from "./OrderItemRow";
import OrderCBMEntry from "./OrderCBMEntry";
import OrderAdditionalEntryForm from "./OrderAdditionalEntryForm";
import { getMaxValue, hasAccess } from "../../helpers/utils";
import { permission } from "../../constants/permission";
import _, { join } from "lodash";
import { categoryService } from "../../services/category-service";
import {
  addNewShippingContactRequest,
  getShippingContactByIdRequest,
  getShippingContactRequest
} from "../../store/shipping-contact/saga";
import { currencyService } from "../../services/currency-service";
import { unitService } from "../../services/unit-service";
import { itemShippingFeeService } from "../../services/item-shipping-fee-service";
import { useSettingsService } from "../../providers/settings.provider";
import moment from "moment/moment";
import UserSelectionModalList from "../../pages/Order/UserSingleSelectionModalList";
import SelectionListModal from "../shipping-contact/SelectionListModal";
import ModalFormShippingContact from "../shipping-contact/ModalFormShippingContact";
import UserSelect from "../user/UserSelect"

const formatOptionLabel = ({ label, phones, address }) => {
  let phoneArr = phones
      ? phones.map(phone => {
        return phone.phoneNumber;
      })
      : [];
  return (
      <div style={ { display: "flex", flexDirection: "column" } }>
      <span className="mb-1">
        <i className="fas fa-user"></i>: <strong>{ label }</strong>
      </span>
        <span className="mb-1">
        <i className="fas fa-phone"></i>: { join(phoneArr, ", ") }
      </span>
        <span className="mb-1">
        <i className="fas fa-address-book"></i>: { address }
      </span>
      </div>
  );
};

const transportationTypeOptions = [
  { key: 1, value: "Sea", label: "Sea" },
  { key: 2, value: "Air", label: "Air" },
];

const deliveryStatusOptions = [
  { key: 0, value: 0, label: "Draft" },
  { key: 1, value: 1, label: "In Korea Warehouse" },
  { key: 2, value: 8, label: "Ready for delivery to Cambodia" },
  { key: 3, value: 2, label: "Delivery To Cambodia" },
  { key: 4, value: 3, label: "In Cambodia Warehouse" },
  { key: 5, value: 4, label: "Delivery To Customer" },
  { key: 6, value: 5, label: "Completed" },
  { key: 7, value: 6, label: "Rejected" },
  { key: 8, value: 7, label: "Closed" },
];

const paymentStatusOptions = [
  { key: 0, value: 0, label: "Draft" },
  { key: 1, value: 1, label: "Invoiced" },
  { key: 2, value: 2, label: "Paid" },
  { key: 3, value: 3, label: "Unpaid" },
];

const typeOptions = [
  { key: 0, value: 0, label: "Box" },
  { key: 1, value: 1, label: "Unit" },
  { key: 2, value: 2, label: "Pallet" },
];


const OrderForm2 = props => {
  const { onCancel, onSubmit } = props;

  const [ order, setOrder ] = useState({
    id: NIL_UUID,
    senderId: null,
    receiverId: null,
    transportationType: {
      key: 1,
      value: "Sea",
      label: "Sea",
    },
    deliveryStatus: {
      key: 0,
      value: 0,
      label: "Draft",
    },
    paymentStatus: {
      key: 0,
      value: 0,
      label: "Draft",
    },
    trackingNumber: '',
    destination: '',
    description: '',
    price: 0,
    currencyId: null,
    quantity: 0,
    weight: 0,
    weightExtra: 0,
    weightComment: '',
    totalAmount: 0.0,
    itemPriceFeeAmount: 0.0,
    weightFeeAmount: 0.0,
    discountPercentage: 0.0,
    discountAmount: 0.0,
    originalReference: '',
    type: { key: 0, value: 0, label: "Box" },
    orderItems: [],
    orderCBMEntries: [],
    unitFee: 0,
    unitFeeAmount: 0.0,
    isDelivery: false,
    isPickup: false,
    deliveryFee: 0,
    pickupFee: 0,
    pickupPaymentStatus: 0,
    deliveryPaymentStatus: 0,
    cargoCode: '',
    commodity: '',
    quantityPackage: '',
    quantityCatton: '',
    categories: [],
    cbmCurrencyId: null,
    cbmExchangeRate: 0,
    exchangeRate: 0,
    cbmRate: 0,
    cbmWeight: 0,
    weightRate: 0,
    createdDate: null,
    eta: null,
    etd: null,
    unitId: null,
    shippingContactId: null,
    orderAdditionalEntries: [
      {
        id: NIL,
        index: 0,
        orderId: null,
        currencyId: null,
        title: "",
        amount: "",
      },
    ],
  });

  const currencyLabel = "";
  const { company } = useSettingsService()
  const [ currencyId, setCurrencyId ] = useState(null);
  const [ submitted, setSubmitted ] = useState(false);
  const [ modalSelectReceiver, setModalSelectReceiver ] = useState(false);
  const [ modalSelectSender, setModalSelectSender ] = useState(false);
  const [ modalAddShippingAddress, setModalAddShippingAddress ] = useState(false);
  const [ modalSelectionShippingAddress, setModalSelectionShippingAddress ] =
      useState(false);
  
  const [ currencyOptions, setCurrencyOptions ] = useState([]);
  const [ unitOptions, setUnitOptions ] = useState([]);
  const [ categoryOptions, setCategoryOptions ] = useState([]);
  const [ shippingAddressOptions, setShippingAddressOptions ] = useState([]);
  const [ itemShippingFeeEntries, setItemShippingFeeEntries ] = useState([]);

  const [ totalVolume, setTotalVolume ] = useState(0);
  const [ totalWeightOrderItem, setTotalWeightOrderItem ] = useState(0);
  const [ orderItemShippingFee, setOrderItemShippingFee ] = useState(0);
  const [ orderItemShippingFeeKRW, setOrderItemShippingFeeKRW ] = useState(0);

  const [ orderCBMEntryShippingFee, setOrderCBMEntryShippingFee ] = useState(0);
  const [ totalOrderAdditionalEntry, setTotalOrderAdditionalEntry ] = useState(0);
  const [ totalOrderCBMEntryVolume, setTotalOrderCBMEntryVolume ] = useState(0);
  const [ orderCBMEntryShippingFeeKRW, setOrderCBMEntryShippingFeeKRW ] =
      useState(0);
  

  useEffect(() => {
    if (order.senderId) {
      itemShippingFeeService
          .getByQuery({
            userId: order.senderId?.value,
            transportationType: null,
            type: null,
            itemPrice: 0,
          })
          .then(data => {
            setItemShippingFeeEntries(data);
          });
    }
  }, [ order?.senderId ]);


  useEffect(() => {
    categoryService.getRequest().then(data => {
      if (data) {
        setCategoryOptions(
            data
                .sort((a, b) => (a.name > b.name ? 1 : -1))
                .map(item => {
                  return {
                    key: item.id,
                    value: item.id,
                    label: item.name,
                  };
                })
        );
      }
    });

    unitService.getRequest().then(data => {
      if (data) {
        let units = data.map(item => {
          return {
            key: item.id,
            value: item.id,
            label: item.name,
          };
        });
        setUnitOptions(units);
      }
    });

    if (props.order) {
      let { order } = props;
      
      populateOrder(order);
    } else {
      currencyService.getDefaultRequest().then(data => {
        if (data) {
          const { defaultCurrency, cbmCurrency } = data;
          initialOrder(defaultCurrency, cbmCurrency);
        }
      });
    }
  }, []);
  
  const handleSelectChange = (valueType, actionMeta) => {
    const { name } = actionMeta;

    switch (name) {
      case 'receiverId':
        let shippingContactId = null;

        if (valueType) {
          getShippingContactRequest({
            userId: valueType?.value,
            pageSize: 50,
          }).then(res => {
            const { data } = res;
            let items = data.map(item => {
              const { id, contact, phones, address } = item;
              return {
                key: id,
                value: id,
                label: contact,
                phones,
                address,
              };
            })

            setShippingAddressOptions(items ? items : []);
            setOrder({
              ...order,
              receiverId: valueType,
              shippingContactId: items && items.length > 0 ? items[0] : null,
            })
          });
        } else {
          setOrder({
            ...order,
            receiverId: valueType,
            shippingContactId: null,
          })
        }

        break;
      default:
        setOrder({
          ...order,
          [name]: valueType,
        });
        break;
    }
  };
  
  const handleSubmit = () => {
    setSubmitted(true);

    let isValid =
        order.senderId &&
        order.receiverId &&
        order.shippingContactId &&
        order.transportationType &&
        order.currencyId &&
        order.unitId &&
        order.type &&
        order.quantity;

    if (isValid) {

      let data = {
        ...order,
        companyId: company?.id,
        senderId: order.senderId.value,
        receiverId: order.receiverId.value,
        transportationType: order.transportationType?.value ?? null,
        deliveryStatus: order.deliveryStatus.value,
        paymentStatus: order.paymentStatus.value,
        shippingContactId: order.shippingContactId?.value ?? null,
        currencyId: order.currencyId?.value,
        unitId: order.unitId?.value,
        type: order.type.value,
        cbmCurrencyId: order.cbmCurrencyId?.value ?? null,
        categories: order.categories.map(category => {
          return {
            id: category.value,
            name: category.label,
          };
        }),
        // orderItems,
        // orderCBMEntries,
        eta: order.eta ? moment(order.eta).format("YYYY-MM-DDTHH:mm:ss.SSS") : null,
        etd: order.etd ? moment(order.etd).format("YYYY-MM-DDTHH:mm:ss.SSS") : null,
        orderAdditionalEntries:
            order.orderAdditionalEntries &&
            order.orderAdditionalEntries
                .filter(e => isValidOrderAdditionalEntry(e))
                .map((a, index) => {
                  return {
                    id: a.id,
                    index,
                    orderId: order.id,
                    currencyId: a.currencyId?.value,
                    title: a.title,
                    amount: a.amount || 0,
                  };
                }),
      };

      onSubmit(data);
    }
  };


  const isValidOrderAdditionalEntry = item => {
    return item.currencyId && item.title && item.amount;
  };

  const handleInputOnChange = (e) => {
    const { name, value } = e.target;

    setOrder({
      ...order,
      [name]: value
    });
  }

  const handleCheckboxOnChange = e => {
    const { name, checked } = e.target;
    setOrder({
      ...order,
      [name]: checked
    })
  }

  const handleCategorySelectChange = (newValue, actionMeta) => {
    const { action } = actionMeta;
    if (action === "create-option") {
      const newItem = newValue.find(e => e.__isNew__ === true);
      const { value } = newItem;
      const category = {
        name: value,
      };

      categoryService.createRequest(category).then(id => {
        if (id) {
          categoryService.getByIdRequest(id).then(data => {
            if (data) {
              const newOption = {
                key: data.id,
                value: data.id,
                label: data.name,
              };

              setOrder({
                ...order,
                categories: order.categories.concat(newValue)
              })
              setCategoryOptions(categoryOptions.concat(newOption));
            }
          });
        }
      });
    } else {
      setOrder({
        ...order,
        categories: order.categories.concat(newValue)
      })
    }
  };

  const handleAddNewOrderItem = () => {
    let max = 0;

    if (order.orderItems.length > 0) {
      max = getMaxValue(
          order.orderItems.map(a => {
            return a.index;
          })
      );
      max += 1;
    } else {
      max = max + 1;
    }

    const newOrderItem = {
      id: NIL_UUID,
      index: max,
      title: "",
      orderId: order.id,
      displayOrder: max,
      quantity: "",
      length: "",
      width: "",
      height: "",
      weight: "",
      volume: "",
    };

    setOrder({
      ...order,
      orderItems: order.orderItems.concat(newOrderItem)
    })
  };

  const handleAddNewOrderCBMEntry = () => {
    let max = 0;

    if (order.orderCBMEntries.length > 0) {
      max = getMaxValue(
          order.orderCBMEntries.map(a => {
            return a.index;
          })
      );
      max += 1;
    } else {
      max = max + 1;
    }

    const newOrderCBMEntry = {
      id: NIL_UUID,
      index: max,
      orderId: order.id,
      cargoCode: "",
      commodity: "",
      quantityPackage: "",
      quantityCatton: "",
      weight: 0,
      volume: 0,
    };

    setOrder({
      ...order,
      orderCBMEntries: order.orderCBMEntries.concat(newOrderCBMEntry)
    })
  };

  const handleCancel = () => {
    onCancel();
  };

  const handleShippingAddressSelectChange = (newValue, actionMeta) => {
    const { action } = actionMeta;
    if (action === "create-option") {
      setModalAddShippingAddress(true);
      // setContact(newValue.value);
    } else {
      // setShippingContactId(newValue);
      setOrder({
        ...order,
        shippingContactId: newValue
      })
    }
  };

  const handleNumberFormatChange = (name, value) => {
    let totalWeight = 0;
    let rate = 0;
    let weightFeeAmount = 0;
    let totalAmount = 0;
    let discountAmount = 0;

    switch (name) {
      case 'weight':
        totalWeight = (value ?? 0) + order.weightExtra;
        if (order.senderId && order.transportationType) {
          itemShippingFeeService
              .getSingleByQuery({
                userId: order.senderId?.value,
                transportationType: order.transportationType?.value,
                type: 0, // 'Weight
                itemPrice: totalWeight,
              })
              .then(({ value: rate }) => {
                weightFeeAmount = totalWeight * rate;

                totalAmount = orderItemShippingFeeKRW +
                    orderCBMEntryShippingFeeKRW +
                    weightFeeAmount +
                    order.unitFee +
                    order.pickupFee +
                    order.deliveryFee +
                    totalOrderAdditionalEntry;

                discountAmount = (totalAmount * order.discountPercentage) / 100;
                totalAmount = totalAmount - discountAmount;
                
                setOrder({
                  ...order,
                  weight: value ?? 0,
                  weightRate: rate,
                  discountAmount,
                  weightFeeAmount,
                  totalAmount
                })
              });
        }
        break;
      case 'weightExtra':
        totalWeight = (value ?? 0) + order.weight;
        
        if (order.senderId && order.transportationType) {
          itemShippingFeeService
              .getSingleByQuery({
                userId: order.senderId?.value,
                transportationType: order.transportationType?.value,
                type: 0, // 'Weight
                itemPrice: totalWeight,
              })
              .then(({ value: rate }) => {
                weightFeeAmount = totalWeight * rate;
                totalAmount = orderItemShippingFeeKRW +
                    orderCBMEntryShippingFeeKRW +
                    weightFeeAmount +
                    order.unitFee +
                    order.pickupFee +
                    order.deliveryFee +
                    totalOrderAdditionalEntry;

                discountAmount = (totalAmount * order.discountPercentage) / 100;
                totalAmount = totalAmount - discountAmount;

                setOrder({
                  ...order,
                  weightExtra: value ?? 0,
                  weightRate: rate,
                  weightFeeAmount,
                  discountAmount,
                  totalAmount
                })
              })
        }
        break;
      case 'discountPercentage':
        totalAmount = orderItemShippingFeeKRW +
            orderCBMEntryShippingFeeKRW +
            order.weightFeeAmount +
            order.unitFee +
            order.pickupFee +
            order.deliveryFee +
            totalOrderAdditionalEntry;

        discountAmount = (totalAmount * (value ?? 0)) / 100;
        totalAmount = totalAmount - discountAmount;

        setOrder({
          ...order,
          discountPercentage: value ?? 0,
          discountAmount,
          totalAmount,
        })
        break;
      case 'unitFee':
        totalAmount = orderItemShippingFeeKRW +
            orderCBMEntryShippingFeeKRW +
            order.weightFeeAmount +
            (value ?? 0) +
            order.pickupFee +
            order.deliveryFee +
            totalOrderAdditionalEntry;

        discountAmount = (totalAmount * order.discountPercentage) / 100;
        totalAmount = totalAmount - discountAmount;

        setOrder({
          ...order,
          unitFee: value ?? 0,
          discountAmount,
          totalAmount,
        })
        break;
      case 'pickupFee':
        totalAmount = orderItemShippingFeeKRW +
            orderCBMEntryShippingFeeKRW +
            order.weightFeeAmount +
            order.unitFee +
            (value ?? 0) +
            order.deliveryFee +
            totalOrderAdditionalEntry;

        discountAmount = (totalAmount * order.discountPercentage) / 100;
        totalAmount = totalAmount - discountAmount;

        setOrder({
          ...order,
          pickupFee: value ?? 0,
          discountAmount,
          totalAmount,
        })
        break;

      case 'deliveryFee':
        totalAmount = orderItemShippingFeeKRW +
            orderCBMEntryShippingFeeKRW +
            order.weightFeeAmount +
            order.unitFee +
            order.pickupFee +
            (value ?? 0) +
            totalOrderAdditionalEntry;
        discountAmount = (totalAmount * order.discountPercentage) / 100;
        totalAmount = totalAmount - discountAmount;

        setOrder({
          ...order,
          deliveryFee: value ?? 0,
          discountAmount,
          totalAmount,
        })
        break;
      case 'cbmExchangeRate':
        const result = calculateCbmExchangeRateChange(order, value ?? 0, order.orderItems);
        totalAmount = result.orderItemShippingFeeKRW +
          orderCBMEntryShippingFeeKRW +
          order.weightFeeAmount +
          order.unitFee +
          order.pickupFee +
          totalOrderAdditionalEntry;
        discountAmount = (totalAmount * order.discountPercentage) / 100;
        totalAmount = totalAmount - discountAmount;

        setOrder({
          ...order,
          cbmExchangeRate: value ?? 0,
          discountAmount,
          totalAmount,
        })
        setOrderItemShippingFeeKRW(result.orderItemShippingFeeKRW)
        break;
      case 'cbmExchangeRate1':
        const result1 = calculateOrderCBMEntriesByExchangeRateChange(order, value ?? 0, order.orderCBMEntries);
        totalAmount = orderItemShippingFeeKRW +
          result1.orderCBMEntryShippingFeeKRW +
          order.weightFeeAmount +
          order.unitFee +
          order.pickupFee +
          totalOrderAdditionalEntry;
        discountAmount = (totalAmount * order.discountPercentage) / 100;
        totalAmount = totalAmount - discountAmount;
  
        setOrder({
          ...order,
          cbmExchangeRate: value ?? 0,
          discountAmount,
          totalAmount,
        })
        setOrderCBMEntryShippingFeeKRW(result1.orderCBMEntryShippingFeeKRW)
        break;
      default:
        setOrder({
          ...order,
          [name]: value
        })
        break;
    }
  }

  const initialOrder = (defaultCurrency, cbmCurrency) => {
    setOrder({
      id: NIL_UUID,
      senderId: null,
      receiverId: null,
      transportationType: {
        key: 1,
        value: "Sea",
        label: "Sea",
      },
      deliveryStatus: {
        key: 0,
        value: 0,
        label: "Draft",
      },
      paymentStatus: {
        key: 0,
        value: 0,
        label: "Draft",
      },
      trackingNumber: '',
      destination: '',
      description: '',
      price: 0,
      currencyId: defaultCurrency ? {
        key: defaultCurrency.id,
        value: defaultCurrency.id,
        label: defaultCurrency.symbol
      } : null,
      quantity: 0,
      weight: 0,
      weightExtra: 0,
      weightComment: '',
      totalAmount: 0.0,
      itemPriceFeeAmount: 0.0,
      weightFeeAmount: 0.0,
      discountPercentage: 0.0,
      discountAmount: 0.0,
      originalReference: '',
      type: { key: 0, value: 0, label: "Box" },
      orderItems: [],
      orderCBMEntries: [],
      unitFee: 0,
      unitFeeAmount: 0.0,
      isDelivery: false,
      isPickup: false,
      deliveryFee: 0,
      pickupFee: 0,
      pickupPaymentStatus: 0,
      deliveryPaymentStatus: 0,
      cargoCode: '',
      commodity: '',
      quantityPackage: '',
      quantityCatton: '',
      categories: [],
      cbmCurrencyId: {
        key: cbmCurrency.id,
        value: cbmCurrency.id,
        label: cbmCurrency.symbol
      },
      cbmExchangeRate: cbmCurrency.rate,
      exchangeRate: cbmCurrency.rate,
      cbmRate: 0,
      cbmWeight: 0,
      weightRate: 0,
      createdDate: null,
      eta: null,
      etd: null,
      unitId: null,
      shippingContactId: null,
      orderAdditionalEntries: [
        {
          id: NIL,
          index: 0,
          orderId: null,
          currencyId: null,
          title: "",
          amount: "",
        },
      ],
    });
  }

  const getShippingFee = (senderId, actualValue, type, transportationType) => {
    if (senderId && transportationType) {
      let shippingFee = itemShippingFeeEntries.find(e => e.userId === senderId && e.from <= actualValue && e.to >= actualValue && e.type === type && e.transportationType === transportationType);

      if (shippingFee) {
        return shippingFee?.value ?? 0;
      } else {
        if (transportationType === 'Air') {
          return 16500;  //// Equal to 15$
        }

        switch (type) {
          case 'Weight':
            return 2500;
          case 'CBM Rate':
            return 250;
          default:
            return 2500;
        }
      }
    }

    return 0;
  }

  const calculateOrderItems2 = (order, orderItems) => {
    let totalWeightOrderItem = _.sumBy(orderItems, a => {
      return Number(a.weight);
    });

    let totalVolume = _.sumBy(orderItems, a => {
      return a.volume;
    });

    // let rate = getShippingFee(order.senderId?.value, totalVolume, "CBM Rate", order.transportationType?.value);
    let orderItemShippingFee = Number(totalVolume ?? 0) * order.cbmRate;
    

    let orderItemShippingFeeKRW = order.cbmExchangeRate * Number(orderItemShippingFee).toFixed(2);

    return {
      totalVolume,
      totalWeightOrderItem,
      orderItemShippingFee,
      orderItemShippingFeeKRW
    }
  }
  
  const calculateOrderItems = (order, orderItems) => {
    let totalWeightOrderItem = _.sumBy(orderItems, a => {
      return Number(a.weight);
    });

    let totalVolume = _.sumBy(orderItems, a => {
      return a.volume;
    });

    let rate = getShippingFee(order.senderId?.value, totalVolume, "CBM Rate", order.transportationType?.value);
    let orderItemShippingFee = Number(totalVolume ?? 0) * rate;

    let orderItemShippingFeeKRW = order.cbmExchangeRate * Number(orderItemShippingFee).toFixed(2);

    return {
      rate,
      totalVolume,
      totalWeightOrderItem,
      orderItemShippingFee,
      orderItemShippingFeeKRW
    }
  }

  const calculateCbmExchangeRateChange = (order, cbmExchangeRate, orderItems) => {
    let totalWeightOrderItem = _.sumBy(orderItems, a => {
      return Number(a.weight);
    });

    let totalVolume = _.sumBy(orderItems, a => {
      return a.volume;
    });

    let rate = getShippingFee(order.senderId?.value, totalVolume, "CBM Rate", order.transportationType?.value);
    let orderItemShippingFee = Number(totalVolume ?? 0) * rate;

    let orderItemShippingFeeKRW = cbmExchangeRate * Number(orderItemShippingFee).toFixed(2);

    return {
      rate,
      totalVolume,
      totalWeightOrderItem,
      orderItemShippingFee,
      orderItemShippingFeeKRW
    }
  }

  const calculateOrderCBMEntries2 = (order, orderCBMEntries) => {
    let totalOrderCBMEntryVolume = _.sumBy(orderCBMEntries, a => {
      return a.volume;
    });

    let orderCBMEntryShippingFee = Number(
        (totalOrderCBMEntryVolume ?? 0) * order.cbmRate
    );

    let orderCBMEntryShippingFeeKRW =
        order.cbmExchangeRate * Number(orderCBMEntryShippingFee).toFixed(2);

    return {
      totalOrderCBMEntryVolume,
      orderCBMEntryShippingFee,
      orderCBMEntryShippingFeeKRW,
    }
  }

  const calculateOrderCBMEntries = (order, orderCBMEntries) => {
    let totalOrderCBMEntryVolume = _.sumBy(orderCBMEntries, a => {
      return a.volume;
    });

    let rate = getShippingFee(order.senderId?.value, totalOrderCBMEntryVolume, "CBM Rate", order.transportationType?.value);

    let orderCBMEntryShippingFee = Number(
        (totalOrderCBMEntryVolume ?? 0) * rate
    );

    let orderCBMEntryShippingFeeKRW =
        order.cbmExchangeRate * Number(orderCBMEntryShippingFee).toFixed(2);

    return {
      rate,
      totalOrderCBMEntryVolume,
      orderCBMEntryShippingFee,
      orderCBMEntryShippingFeeKRW,
    }
  }

  const calculateOrderCBMEntriesByExchangeRateChange = (order, cbmExchangeRate, orderCBMEntries) => {
    let totalOrderCBMEntryVolume = _.sumBy(orderCBMEntries, a => {
      return a.volume;
    });

    let rate = getShippingFee(order.senderId?.value, totalOrderCBMEntryVolume, "CBM Rate", order.transportationType?.value);

    let orderCBMEntryShippingFee = Number(
      (totalOrderCBMEntryVolume ?? 0) * rate
    );

    let orderCBMEntryShippingFeeKRW = cbmExchangeRate * Number(orderCBMEntryShippingFee).toFixed(2);

    return {
      rate,
      totalOrderCBMEntryVolume,
      orderCBMEntryShippingFee,
      orderCBMEntryShippingFeeKRW,
    }
  }

  const calculateAdditionalEntries = (order, orderAdditionalEntries) => {
    let totalOrderAdditionalEntry = 0;

    orderAdditionalEntries.forEach(item => {
      const { amount } = item;
      if (item.currencyId) {
        if (item.currencyId.name === "USD") {
          totalOrderAdditionalEntry += Number(amount) * order.exchangeRate;
        } else {
          totalOrderAdditionalEntry += Number(amount);
        }
      }
    });

    return {
      totalOrderAdditionalEntry
    }
  }

  const populateOrder = (order) => {
    let orderUpdated = {
      ...order,
      senderId: order.senderId ? {
        key: order.senderId,
        value: order.senderId,
        label: order.sender,
        uniqueId: order.senderUniqueId,
      } : null,
      receiverId: order.receiverId ?
          {
            key: order.receiverId,
            value: order.receiverId,
            label: order.receiver,
            uniqueId: order.receiverUniqueId,
          } : null,
      transportationType: order.transportationType
          ? transportationTypeOptions.find(
              e => e.value === order.transportationType
          )
          : {
            key: 1,
            value: "Sea",
            label: "Sea",
          },
      deliveryStatus: {
        key: order.deliveryStatus,
        value: order.deliveryStatus,
        label: order.deliveryStatusName,
      },
      paymentStatus: {
        key: order.paymentStatus,
        value: order.paymentStatus,
        label: order.paymentStatusName,
      },
      currencyId: order.currencyId ? {
        key: order.currencyId,
        value: order.currencyId,
        label: order.currency,
      } : null,
      cbmCurrencyId: order.cbmCurrencyId ? {
        key: order.cbmCurrencyId,
        value: order.cbmCurrencyId,
        label: order.cbmCurrency,
      } : null,
      shippingContactId: {
        key: order.shippingContactObject.id,
        value: order.shippingContactId,
        label: order.shippingContactObject.contact,
        phones: order.shippingContactObject.phones,
        address: order.shippingContactObject.address,
      },
      unitId: order.unitId ? {
        key: order.unitId,
        value: order.unitId,
        label: order.unit,
      } : null,
      categories: order.categories.map(category => {
        return {
          key: category.id,
          value: category.id,
          label: category.name,
        };
      }),
      type: order
          ? typeOptions.find(e => e.value === order.type)
          : { key: 0, value: 0, label: "Box" },
      orderItems: order.orderItems.map((a, index) => {
        return {
          ...a,
          index,
        };
      }),
      orderCBMEntries: order.orderCBMEntries.map((a, index) => {
        return {
          ...a,
          index,
        };
      }),
      
      orderAdditionalEntries: order ? order.orderAdditionalEntries && order.orderAdditionalEntries.length > 0
              ? order.orderAdditionalEntries.map((a, index) => {
                return {
                  ...a,
                  index,
                  currencyId: a.currencyId
                      ? {
                        key: a.currencyId,
                        value: a.currencyId,
                        label: a.currencySymbol,
                        name: a.currencyLabel,
                      }
                      : {},
                };
              })
              : [
                {
                  id: NIL,
                  index: 0,
                  orderId: null,
                  currencyId: null,
                  title: "",
                  amount: "",
                },
              ]
          : [
            {
              id: NIL,
              index: 0,
              orderId: null,
              currencyId: null,
              title: "",
              amount: "",
            },
          ],
      eta: order.eta ? moment(order.eta).toDate() : null,
      etd: order.etd ? moment(order.etd).toDate() : null,
      createdDate: order.createdDate ? moment(order.createdDate).toDate() : null,
    }

    const {
      totalVolume,
      totalWeightOrderItem,
      orderItemShippingFee,
      orderItemShippingFeeKRW
    } = calculateOrderItems2(orderUpdated, orderUpdated.orderItems);

    setTotalVolume(totalVolume);
    setTotalWeightOrderItem(totalWeightOrderItem);
    setOrderItemShippingFee(orderItemShippingFee);
    setOrderItemShippingFeeKRW(orderItemShippingFeeKRW);

    // Calculate orderCBMEntries
    const {
      totalOrderCBMEntryVolume,
      orderCBMEntryShippingFee,
      orderCBMEntryShippingFeeKRW,
    } = calculateOrderCBMEntries2(orderUpdated, orderUpdated.orderCBMEntries);
    setTotalOrderCBMEntryVolume(totalOrderCBMEntryVolume);
    setOrderCBMEntryShippingFee(orderCBMEntryShippingFee);
    setOrderCBMEntryShippingFeeKRW(orderCBMEntryShippingFeeKRW);

    const { totalOrderAdditionalEntry } = calculateAdditionalEntries(orderUpdated, orderUpdated.orderAdditionalEntries);
    setTotalOrderAdditionalEntry(totalOrderAdditionalEntry);

    let totalAmount = orderItemShippingFeeKRW +
        orderCBMEntryShippingFeeKRW +
        order.weightFeeAmount +
        order.unitFee +
        order.pickupFee +
        order.deliveryFee +
        totalOrderAdditionalEntry;
    let discountAmount = totalAmount / 100 * order.discountPercentage;
    totalAmount = totalAmount - discountAmount;

    setOrder({
      ...orderUpdated,
      totalAmount,
      discountAmount,
    })
  }

  const handleOnOrderItemChange = orderItem => {
    let orderItemsChange = order.orderItems.map(a => {
      return a.index === order.index ? orderItem : a
    });

    const {
      rate,
      totalVolume,
      totalWeightOrderItem,
      orderItemShippingFee,
      orderItemShippingFeeKRW
    } = calculateOrderItems(order, orderItemsChange);

    setOrderItemShippingFee(orderItemShippingFee);
    setOrderItemShippingFeeKRW(orderItemShippingFeeKRW);

    setTotalVolume(totalVolume);
    setTotalWeightOrderItem(totalWeightOrderItem);

    let totalAmount = orderItemShippingFeeKRW +
        orderCBMEntryShippingFeeKRW +
        order.weightFeeAmount +
        order.unitFee +
        order.pickupFee +
        order.deliveryFee +
        totalOrderAdditionalEntry;

    let discountAmount = totalAmount / 100 * order.discountPercentage;
    totalAmount = totalAmount - discountAmount;

    setOrder({
      ...order,
      cbmRate: rate,
      discountAmount,
      totalAmount,
      orderItems: orderItemsChange
    });
  };

  const handleOnRemoveOrderItem = item => {
    let orderItemsChange = order.orderItems.filter(e => e.index !== item.index)

    const {
      rate,
      totalVolume,
      totalWeightOrderItem,
      orderItemShippingFee,
      orderItemShippingFeeKRW
    } = calculateOrderItems(order, orderItemsChange);

    setOrderItemShippingFee(orderItemShippingFee);
    setOrderItemShippingFeeKRW(orderItemShippingFeeKRW);

    setTotalVolume(totalVolume);
    setTotalWeightOrderItem(totalWeightOrderItem);

    let totalAmount = orderItemShippingFeeKRW +
        orderCBMEntryShippingFeeKRW +
        order.weightFeeAmount +
        order.unitFee +
        order.pickupFee +
        order.deliveryFee +
        totalOrderAdditionalEntry;

    let discountAmount = totalAmount / 100 * order.discountPercentage;
    totalAmount = totalAmount - discountAmount;

    setOrder({
      ...order,
      cbmRate: rate,
      discountAmount,
      totalAmount,
      orderItems: orderItemsChange ?? []
    })
  };

  const handleOnRemoveOrderCBMEntry = orderCBMEntry => {
    let orderCBMEntriesChange = order.orderCBMEntries.filter(e => e.index !== orderCBMEntry.index);

    const {
      rate,
      totalOrderCBMEntryVolume,
      orderCBMEntryShippingFee,
      orderCBMEntryShippingFeeKRW,
    } = calculateOrderCBMEntries(order, orderCBMEntriesChange);

    setTotalOrderCBMEntryVolume(totalOrderCBMEntryVolume);
    setOrderCBMEntryShippingFee(orderCBMEntryShippingFee);
    setOrderCBMEntryShippingFeeKRW(orderCBMEntryShippingFeeKRW);

    let totalAmount = orderItemShippingFeeKRW +
        orderCBMEntryShippingFeeKRW +
        order.weightFeeAmount +
        order.pickupFee +
        order.deliveryFee +
        order.unitFee +
        totalOrderAdditionalEntry;

    let discountAmount = totalAmount / 100 * order.discountPercentage;
    totalAmount = totalAmount - discountAmount;

    setOrder({
      ...order,
      totalAmount,
      discountAmount,
      orderCBMEntries: orderCBMEntriesChange
    })
  };

  const handleOnChangeOrderCBMEntry = orderCBMEntry => {
    let orderCBMEntriesChange = order.orderCBMEntries.map(a => {
      return a.index === orderCBMEntry.index ? orderCBMEntry : a
    })

    const {
      rate,
      totalOrderCBMEntryVolume,
      orderCBMEntryShippingFee,
      orderCBMEntryShippingFeeKRW,
    } = calculateOrderCBMEntries(order, orderCBMEntriesChange);

    setTotalOrderCBMEntryVolume(totalOrderCBMEntryVolume);
    setOrderCBMEntryShippingFee(orderCBMEntryShippingFee);

    setOrderCBMEntryShippingFeeKRW(orderCBMEntryShippingFeeKRW);

    let totalAmount = orderItemShippingFeeKRW +
        orderCBMEntryShippingFeeKRW +
        order.weightFeeAmount +
        order.pickupFee +
        order.deliveryFee +
        order.unitFee +
        totalOrderAdditionalEntry;

    let discountAmount = totalAmount / 100 * order.discountPercentage;

    setOrder({
      ...order,
      cbmRate: rate,
      totalAmount,
      discountAmount,
      orderCBMEntries: orderCBMEntriesChange
    })
  };

  const handleOnAdd = () => {
    let max = 0;

    if (order.orderAdditionalEntries.length > 0) {
      max = getMaxValue(
          order.orderAdditionalEntries.map(a => {
            return a.index;
          })
      );
      max += 1;
    } else {
      max = max + 1;
    }

    const newItem = {
      index: max,
      id: NIL,
      title: "",
      currencyId: null,
      orderId: null,
      amount: 0,
    };

    setOrder({
      ...order,
      orderAdditionalEntries: order.orderAdditionalEntries.concat(newItem)
    })
  };

  const handleOnRemove = item => {
    let orderAdditionalEntries = order.orderAdditionalEntries.filter(e => e.index !== item.index);
    const { totalOrderAdditionalEntry } = calculateAdditionalEntries(order, orderAdditionalEntries);
    setTotalOrderAdditionalEntry(totalOrderAdditionalEntry);

    let totalAmount =
        orderItemShippingFeeKRW +
        orderCBMEntryShippingFeeKRW +
        order.weightFeeAmount +
        order.unitFee +
        order.pickupFee +
        order.deliveryFee +
        totalOrderAdditionalEntry;

    let discountAmount = totalAmount / 100 * order.discountPercentage;
    totalAmount = totalAmount - discountAmount;

    setOrder({
      ...order,
      totalAmount,
      discountAmount,
      orderAdditionalEntries
    })
  };

  const handleOnChange = item => {
    let orderAdditionalEntries = order.orderAdditionalEntries.map(a => {
      return a.index === item.index ? item : a
    });

    const { totalOrderAdditionalEntry } = calculateAdditionalEntries(order, orderAdditionalEntries);
    setTotalOrderAdditionalEntry(totalOrderAdditionalEntry);

    let totalAmount =
        orderItemShippingFeeKRW +
        orderCBMEntryShippingFeeKRW +
        order.weightFeeAmount +
        order.unitFee +
        order.pickupFee +
        order.deliveryFee +
        totalOrderAdditionalEntry;

    let discountAmount = totalAmount / 100 * order.discountPercentage;
    totalAmount = totalAmount - discountAmount;

    setOrder({
      ...order,
      totalAmount,
      discountAmount,
      orderAdditionalEntries
    })
  };

  const handleSelectSenderUser = user => {
    setModalSelectSender(false);
    setOrder({
      ...order,
      senderId: {
        key: user.id,
        value: user.id,
        label: `${ user.firstName } ${ user.lastName }`,
      }
    })
  };

  const handleSelectReceiverUser = user => {
    populateShippingAddress(user.id);
    setModalSelectReceiver(false);
    setOrder({
      ...order,
      receiverId: {
          key: user.id,
          value: user.id,
          label: `${ user.firstName } ${ user.lastName }`,    
      }
    })
  };

  const populateShippingAddress = userId => {
    getShippingContactRequest({ userId, pageSize: 50 }).then(res => {
      const { data } = res;
      setShippingAddressOptions(
          data.map(item => {
            const { id, contact, phones, address } = item;
            return {
              key: id,
              value: id,
              label: contact,
              phones,
              address,
            };
          })
      );
    });
  };

  const handleSubmitSelectionShippingAddress = item => {
    if (item) {
      setModalSelectionShippingAddress(false);
      setOrder({
        ...order,
        shippingContactId: {
            key: item.id,
            value: item.id,
            label: item.contact,
            address: item.address,
        }
      })
    }
  };

  const hanldeSubmitShippingAddress = shippingAddress => {
    addNewShippingContactRequest(shippingAddress)
        .then(id => {
          const { userId } = shippingAddress;
          getShippingContactByIdRequest(id).then(data => {
            if (data) {
              populateShippingAddress(userId);
              setModalAddShippingAddress(false);
              setOrder({
                ...order,
                shippingContactId: {
                    key: data.id,
                    value: data.id,
                    label: data.contact,
                    phones: data.phones,
                    address: data.address,    
                }
              })
            }
          });
        })
        .catch(err => {
          console.log(err);
        });
  };

  return (
      <>
        <Row>
          <Col lg={ 12 }>
            <Card className="mb-3">
              { order.totalAmount === 0 && order.id !== NIL_UUID ? (
                  <Alert color="warning">
                    <strong>Warning!</strong>
                    <br/>
                    Please check calculation! Total Amount this order is zero.
                  </Alert>
              ) : null }

              <CardBody className="pt-3">
                <CardTitle>Order</CardTitle>
                <Row>
                  <Col md={ 4 }>
                    <div className="mb-2">
                      <Label>Sender</Label>
                      <FormGroup
                          className={
                              "select2-container d-flex " +
                              (submitted && !order.senderId ? "is-invalid" : "")
                          }
                      >
                        <div style={ { flexGrow: 1 } }>
                          <UserSelect
                            name="senderId"
                            value={order.senderId}
                            roleNames={[]}
                            onChange={handleSelectChange}
                            className={submitted && !order.senderId ? "is-invalid" : ""}
                          />
                        </div>
                        <Button
                            color="primary"
                            onClick={ () => setModalSelectSender(true) }
                            outline
                        >
                          <i className="bx bx-search-alt"></i> Find
                        </Button>
                      </FormGroup>
                      { submitted && !order.senderId && (
                          <div className="invalid-feedback-custom">
                            Sender is required.
                          </div>
                      ) }
                    </div>
                    <FormGroup
                        className={
                            "select2-container" +
                            (submitted && !order.transportationType ? " is-invalid" : "")
                        }
                    >
                      <Label>Transportation Type</Label>
                      <Select
                          name="transportationType"
                          value={ order.transportationType }
                          onChange={ handleSelectChange }
                          options={ transportationTypeOptions }
                          classNamePrefix="select2-selection"
                          isClearable
                      />
                    </FormGroup>
                    { submitted && !order.transportationType && (
                        <div className="invalid-feedback-custom">
                          Transportation Type is required.
                        </div>
                    ) }
                    <FormGroup
                        className={
                            "select2-container" +
                            (submitted && !order.deliveryStatus ? " is-invalid" : "")
                        }
                    >
                      <Label>Delivery Status</Label>
                      <Select
                          name="deliveryStatus"
                          value={ order.deliveryStatus }
                          onChange={ { handleSelectChange } }
                          options={ deliveryStatusOptions }
                          classNamePrefix="select2-selection"

                          // isDisabled
                          isClearable
                      />
                    </FormGroup>
                    { submitted && !order.deliveryStatus && (
                        <div className="invalid-feedback-custom">
                          Delivery Status is required.
                        </div>
                    ) }
                  </Col>
                  <Col md={ 4 }>
                    <div className="mb-2">
                      <Label>Receiver</Label>
                      <FormGroup
                          className={
                              "select2-container d-flex " +
                              (submitted && !order.receiverId ? "is-invalid" : "")
                          }
                      >
                        <div style={ { flexGrow: 1 } }>
                          <UserSelect
                            name="receiverId"
                            value={order.receiverId}
                            roleNames={[]}
                            onChange={handleSelectChange}
                            className={submitted && !order.receiverId ? "is-invalid" : ""}
                          />
                        </div>
                        <Button
                            color="primary"
                            onClick={ () => setModalSelectReceiver(true) }
                            outline
                        >
                          <i className="bx bx-search-alt"></i> Find
                        </Button>
                      </FormGroup>
                      { submitted && !order.receiverId && (
                          <div className="invalid-feedback-custom">
                            Receiver is required.
                          </div>
                      ) }
                    </div>
                    <FormGroup className="mb-3">
                      <Label>Tracking Number</Label>
                      <Input
                          type="text"
                          name="trackingNumber"
                          value={ order.trackingNumber }
                          readOnly
                      />
                    </FormGroup>
                    <FormGroup
                        className={
                            "select2-container" +
                            (submitted && !order.paymentStatus ? " is-invalid" : "")
                        }
                    >
                      <Label>Shipping Payment Status</Label>
                      <Select
                          name="paymentStatus"
                          value={ order.paymentStatus }
                          onChange={ handleSelectChange }
                          options={ paymentStatusOptions }
                          classNamePrefix="select2-selection"
                          isDisabled
                          isClearable
                      />
                    </FormGroup>
                    { submitted && !order.paymentStatus && (
                        <div className="invalid-feedback-custom">
                          Payment Status is required.
                        </div>
                    ) }
                  </Col>
                  <Col md={ 4 }>
                    <div className="mb-2">
                      <Label>Shipping Contact</Label>
                      <FormGroup
                          className={
                              "select2-container d-flex " +
                              (submitted && !order.shippingContactId ? "is-invalid" : "")
                          }
                      >
                        <div style={ { flexGrow: 1 } }>
                          <CreatableSelect
                              name="shippingContactId"
                              value={ order.shippingContactId }
                              onChange={ handleShippingAddressSelectChange }
                              options={ shippingAddressOptions }
                              className="custom-shipping-addr"
                              classNamePrefix="select2-selection"
                              formatOptionLabel={ formatOptionLabel }
                              isDisabled={ !order.receiverId }
                              isClearable
                          />
                        </div>
                        <Button
                            color="primary"
                            onClick={ () => setModalSelectionShippingAddress(true) }
                            outline
                            disabled={ !order.receiverId }
                        >
                          <i className="bx bx-search-alt"></i> Find
                        </Button>
                      </FormGroup>
                      { submitted && !order.shippingContactId && (
                          <div className="invalid-feedback-custom">
                            Shipping Contact is required.
                          </div>
                      ) }
                    </div>

                    <FormGroup className="mb-3">
                      <Label htmlFor="originalReference">
                        Original Reference
                      </Label>
                      <Input
                          type="textarea"
                          name="originalReference"
                          rows={ 3 }
                          placeholder="Original Reference"
                          value={ order.originalReference }
                          onChange={ handleInputOnChange }
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col md={ 12 }>
            <Card>
              <CardBody>
                <CardTitle>Dates</CardTitle>
                <Row>
                  <Col md={ 4 }>
                    <FormGroup>
                      <Label htmlFor="createdDate">Whs in Date</Label>
                      <DatePicker
                          id="createdDate"
                          name="createdDate"
                          className={ "form-control" }
                          selected={ order.createdDate }
                          dateFormat="dd-MMM-yyyy"
                          placeholderText="Whs in Date"
                          autoComplete="off"
                          readOnly={ true }
                          isClearable
                      />
                    </FormGroup>
                  </Col>
                  <Col md={ 4 }>
                    <FormGroup>
                      <Label htmlFor="etd">
                        Estimate Time of Departure (ETD)
                      </Label>
                      <DatePicker
                          className="form-control"
                          name="etd"
                          selected={ order.etd }
                          readOnly={ true }
                          dateFormat="dd-MMM-yyyy"
                          placeholderText="Estimate Time of Departure (ETD)"
                          autoComplete="off"
                          isClearable
                      />
                    </FormGroup>
                  </Col>
                  <Col md={ 4 }>
                    <FormGroup>
                      <Label htmlFor="eta">Estimate Time of Arrival (ETA)</Label>
                      <DatePicker
                          className="form-control"
                          name="eta"
                          selected={ order.eta }
                          readOnly={ true }
                          dateFormat="dd-MMM-yyyy"
                          placeholderText="Estimate Time of Arrival (ETA)"
                          autoComplete="off"
                          isClearable
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>

          <Col md={ 12 }>
            <Card>
              <CardBody>
                <CardTitle>Order Detail</CardTitle>
                <Row>
                  <Col md={ 4 }>
                    <FormGroup className="mb-3">
                      <Label htmlFor="validationCustom01">Description</Label>
                      <Input
                          type="text"
                          name="description"
                          placeholder="Description"
                          value={ order.description }
                          onChange={ handleInputOnChange }
                      />
                    </FormGroup>
                  </Col>
                  <Col md={ 4 }>
                    <FormGroup className="select2-container">
                      <Label>Category</Label>
                      <CreatableSelect
                          name="categories"
                          value={ order.categories }
                          isMulti={ true }
                          onChange={ handleCategorySelectChange }
                          options={ categoryOptions }
                          classNamePrefix="select2-selection"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={ 4 }>
                    <div className="mb-3">
                      <div
                          className={
                              "select2-container" +
                              (submitted && !order.type ? " is-invalid" : "")
                          }
                      >
                        <Label>Type</Label>
                        <Select
                            name="type"
                            value={ order.type }
                            onChange={ handleSelectChange }
                            options={ typeOptions }
                            classNamePrefix="select2-selection"
                            isClearable
                        />
                      </div>
                      { submitted && !order.type && (
                          <div className="invalid-feedback-custom">
                            Type is required.
                          </div>
                      ) }
                    </div>
                  </Col>
                  <Col md={ 3 }>
                    <FormGroup className="mb-3">
                      <Label htmlFor="validationCustom01">Item Price</Label>
                      <NumberFormat
                          name="price"
                          value={ order.price }
                          className="form-control text-end"
                          thousandSeparator={ true }
                          fixedDecimalScale={ false }
                          placeholder="0"
                          prefix={ `${ currencyLabel } ` }
                          onValueChange={ values => {
                            const { floatValue } = values;
                            handleNumberFormatChange('price', floatValue);
                          } }
                      />
                    </FormGroup>
                  </Col>
                  <Col md={ 3 }>
                    <div className="mb-3">
                      <div
                          className={
                              "select2-container" +
                              (submitted && !order.currencyId ? " is-invalid" : "")
                          }
                      >
                        <Label>Currency</Label>
                        <Select
                            name="currencyId"
                            value={ order.currencyId }
                            onChange={ option => setCurrencyId(option) }
                            options={ currencyOptions }
                            classNamePrefix="test select2-selection"
                            isClearable
                            isDisabled
                        />
                      </div>
                      { submitted && !order.currencyId && (
                          <div className="invalid-feedback-custom">
                            Currency is required.
                          </div>
                      ) }
                    </div>
                  </Col>
                  <Col md={ 3 }>
                    <FormGroup className="mb-3">
                      <div className={ submitted && !order.quantity ? "is-invalid" : "" }>
                        <Label>Quantity</Label>
                        <NumberFormat
                            name="quantity"
                            value={ order.quantity }
                            className={
                                "form-control text-end" +
                                (submitted && !order.quantity ? " is-invalid" : "")
                            }
                            placeholder="0"
                            thousandSeparator={ true }
                            fixedDecimalScale={ true }
                            onValueChange={ values => {
                              const { floatValue } = values;
                              handleNumberFormatChange('quantity', floatValue);
                            } }
                        />
                      </div>

                      { submitted && !order.quantity && (
                          <div className="invalid-feedback-custom">
                            Quantity is required.
                          </div>
                      ) }
                    </FormGroup>
                  </Col>
                  <Col md={ 3 }>
                    <div className="mb-3">
                      <div
                          className={
                              "select2-container" +
                              (submitted && !order.unitId ? " is-invalid" : "")
                          }
                      >
                        <Label>Unit</Label>
                        <Select
                            name="unitId"
                            value={ order.unitId }
                            onChange={ handleSelectChange }
                            options={ unitOptions }
                            classNamePrefix="select2-selection"
                            isClearable
                        />
                      </div>
                      { submitted && !order.unitId && (
                          <div className="invalid-feedback-custom">
                            Unit is required.
                          </div>
                      ) }
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>

          <Col md={ 12 }>
            <Card>
              <CardHeader className="bg-transparent border pb-1">
                <div className="d-flex justify-content-between">
                  <CardTitle>CBM Item Detail</CardTitle>
                  <span
                      className="px-2"
                      style={ { cursor: "pointer" } }
                      onClick={ handleAddNewOrderItem }
                  >
                  <i className="fas fa-plus text-primary"/>
                </span>
                </div>
              </CardHeader>
              <CardBody>
                { order.orderItems.map((item, index) => {
                  return (
                      <OrderItemRow
                          key={ index }
                          isFirst={ index === 0 }
                          item={ item }
                          onChange={ handleOnOrderItemChange }
                          onRemove={ handleOnRemoveOrderItem }
                      />
                  );
                }) }
                { order.orderItems.length > 0 && (
                    <>
                      <Row className="mb-2">
                        <Col md={ 3 }>
                          <Input
                              type="text"
                              name="cargoCode"
                              maxLength={ 255 }
                              placeholder="Cargo Code"
                              value={ order.cargoCode }
                              onChange={ handleInputOnChange }
                          />
                        </Col>
                        <Col className={ "text-end" }>
                          <Label className="pt-2">Total Volume</Label>
                        </Col>
                        <Col md={ 2 } className="pe-5">
                          <NumberFormat
                              name="totalVolume"
                              value={ Number(totalVolume).toFixed(3) }
                              className="form-control text-end"
                              thousandSeparator={ true }
                              fixedDecimalScale={ false }
                              readOnly
                          />
                        </Col>
                      </Row>
                      <Row className="mb-2">
                        <Col md={ 3 }>
                          <Input
                              type="text"
                              name="commodity"
                              maxLength={ 255 }
                              placeholder="Commodity"
                              value={ order.commodity }
                              onChange={ handleInputOnChange }
                          />
                        </Col>
                        <Col className={ "text-end" }>
                          <Label className="pt-2">Total Weight</Label>
                        </Col>
                        <Col md={ 2 } className="pe-5">
                          <NumberFormat
                              name="totalWeightOrderItem"
                              value={ Number(totalWeightOrderItem).toFixed(2) }
                              className="form-control text-end"
                              thousandSeparator={ true }
                              fixedDecimalScale={ false }
                              readOnly
                          />
                        </Col>
                      </Row>
                      <Row className="mb-2">
                        <Col md={ 3 }>
                          <Input
                              type="text"
                              name="quantityPackage"
                              maxLength={ 255 }
                              placeholder="PLTS/PKGS"
                              value={ order.quantityPackage }
                              onChange={ handleInputOnChange }
                          />
                        </Col>
                        <Col className={ "text-end" }>
                          <Label className="pt-2">CBM Rate (USD)</Label>
                        </Col>
                        <Col md={ 2 } className="pe-5">
                          <NumberFormat
                              name="cbmRates"
                              value={ Number(order.cbmRate).toFixed(2) }
                              className="form-control text-end"
                              thousandSeparator={ true }
                              fixedDecimalScale={ false }
                              readOnly
                          />
                        </Col>
                      </Row>
                      <Row className="mb-2">
                        <Col md={ 3 }>
                          <Input
                              type="text"
                              name="quantityCatton"
                              maxLength={ 255 }
                              placeholder="PCS/CTNS"
                              value={ order.quantityCatton }
                              onChange={ handleInputOnChange }
                          />
                        </Col>
                        <Col className={ "text-end" }>
                          <Label className="pt-2">Shipping Fee (USD)</Label>
                        </Col>
                        <Col md={ 2 } className="pe-5">
                          <NumberFormat
                              name="orderItemShippingFee"
                              value={ Number(orderItemShippingFee).toFixed(2) }
                              className="form-control text-end"
                              thousandSeparator={ true }
                              fixedDecimalScale={ false }
                              readOnly
                          />
                        </Col>
                      </Row>
                      <Row className="mb-2">
                        <Col className={ "text-end" }>
                          <Label className="pt-2">Exchange Rate (KRW)</Label>
                        </Col>
                        <Col md={ 2 } className="pe-5">
                          <NumberFormat
                              name="cbmExchangeRate"
                              value={ order.cbmExchangeRate }
                              className="form-control text-end"
                              thousandSeparator={ true }
                              fixedDecimalScale={ false }
                              onValueChange={ values => {
                                const { floatValue } = values;
                                handleNumberFormatChange('cbmExchangeRate', floatValue);
                              } }
                          />
                        </Col>
                      </Row>
                      <Row className="mb-2">
                        <Col className={ "text-end" }>
                          <Label className="pt-2">Shipping Fee (KRW)</Label>
                        </Col>
                        <Col md={ 2 } className="pe-5">
                          <NumberFormat
                              name="orderItemShippingFeeKRW"
                              value={ Number(orderItemShippingFeeKRW).toFixed(0) }
                              className="form-control text-end"
                              thousandSeparator={ true }
                              fixedDecimalScale={ false }
                              readOnly
                          />
                        </Col>
                      </Row>
                    </>
                ) }
              </CardBody>
            </Card>
          </Col>
          <Col md={ 12 }>
            <Card>
              <CardHeader className="bg-transparent border pb-1">
                <div className="d-flex justify-content-between">
                  <CardTitle>CBM Item Detail From Shipper</CardTitle>
                  <span
                      className="px-2"
                      style={ { cursor: "pointer" } }
                      onClick={ handleAddNewOrderCBMEntry }
                  >
                  <i className="fas fa-plus text-primary"/>
                </span>
                </div>
              </CardHeader>
              <CardBody>
                { order.orderCBMEntries.map((item, index) => {
                  return (
                      <OrderCBMEntry
                          item={ item }
                          isFirst={ index === 0 }
                          key={ index }
                          onChange={ handleOnChangeOrderCBMEntry }
                          onRemove={ handleOnRemoveOrderCBMEntry }
                      />
                  );
                }) }
                { order.orderCBMEntries.length > 0 && (
                    <Row className="mb-2">
                      <Col md={ 3 } className="pe-1"></Col>
                      <Col md={ 3 } className="px-1"></Col>
                      <Col md={ 6 } className="pe-5">
                        <Row className="mb-2">
                          <Col md={ 9 } className="px-1">
                            <div
                                className="d-flex flex-column align-items-end"
                                style={ { width: "100%", paddingRight: "10px" } }
                            >
                              <Label className="pt-2">Total Volume</Label>
                            </div>
                          </Col>
                          <Col md={ 3 } className="px-1">
                            <NumberFormat
                                name="totalOrderCBMEntryVolume"
                                value={ Number(totalOrderCBMEntryVolume).toFixed(3) }
                                className="form-control text-end"
                                thousandSeparator={ true }
                                fixedDecimalScale={ false }
                                readOnly
                            />
                          </Col>
                        </Row>
                        <Row className="mb-2">
                          <Col md={ 9 } className="px-1">
                            <div
                                className="d-flex flex-column align-items-end"
                                style={ { width: "100%", paddingRight: "10px" } }
                            >
                              <Label className="pt-2">CBM Rate (USD)</Label>
                            </div>
                          </Col>
                          <Col md={ 3 } className="px-1">
                            <NumberFormat
                                name="cbmRates"
                                value={ Number(order.cbmRate).toFixed(2) }
                                className="form-control text-end"
                                thousandSeparator={ true }
                                fixedDecimalScale={ false }
                                readOnly
                            />
                          </Col>
                        </Row>
                        <Row className="mb-2">
                          <Col md={ 9 } className="px-1">
                            <div
                                className="d-flex flex-column align-items-end"
                                style={ { width: "100%", paddingRight: "10px" } }
                            >
                              <Label className="pt-2">Shipping Fee (USD)</Label>
                            </div>
                          </Col>
                          <Col md={ 3 } className="px-1">
                            <NumberFormat
                                name="orderCBMEntryShippingFee"
                                value={ Number(orderCBMEntryShippingFee).toFixed(2) }
                                className="form-control text-end"
                                thousandSeparator={ true }
                                fixedDecimalScale={ false }
                                readOnly
                            />
                          </Col>
                        </Row>
                        <Row className="mb-2">
                          <Col md={ 9 } className="px-1">
                            <div
                                className="d-flex flex-column align-items-end"
                                style={ { width: "100%", paddingRight: "10px" } }
                            >
                              <Label className="pt-2">Exchange Rate (KRW)</Label>
                            </div>
                          </Col>
                          <Col md={ 3 } className="px-1">
                            <NumberFormat
                                name="cbmExchangeRate1"
                                value={ order.cbmExchangeRate }
                                className="form-control text-end"
                                thousandSeparator={ true }
                                fixedDecimalScale={ false }
                                onValueChange={ values => {
                                  const { floatValue } = values;
                                  handleNumberFormatChange('cbmExchangeRate1', floatValue);
                                } }
                            />
                          </Col>
                        </Row>
                        <Row className="mb-2">
                          <Col md={ 9 } className="px-1">
                            <div
                                className="d-flex flex-column align-items-end"
                                style={ { width: "100%", paddingRight: "10px" } }
                            >
                              <Label className="pt-2">Shipping Fee (KRW)</Label>
                            </div>
                          </Col>
                          <Col md={ 3 } className="px-1">
                            <NumberFormat
                                name="orderCBMEntryShippingFeeKRW"
                                value={ Number(orderCBMEntryShippingFeeKRW).toFixed(0) }
                                className="form-control text-end"
                                thousandSeparator={ true }
                                fixedDecimalScale={ false }
                                readOnly
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                ) }
              </CardBody>
            </Card>
          </Col>
          <Col md={ 9 }>
            <Card>
              <CardBody>
                <CardTitle>Weight and Fee</CardTitle>
                <Row>
                  <Col md={ 4 }>
                    <FormGroup className="mb-3" style={ { flexGrow: 1 } }>
                      <Label>Actual Weight (kg)</Label>
                      <NumberFormat
                          name="weight"
                          value={ order.weight }
                          className="form-control text-end"
                          placeholder="0"
                          thousandSeparator={ true }
                          fixedDecimalScale={ true }
                          onValueChange={ values => {
                            const { floatValue } = values;
                            handleNumberFormatChange('weight', floatValue);
                          } }
                      />
                    </FormGroup>
                  </Col>
                  <Col md={ 4 }>
                    <FormGroup className="mb-3" style={ { flexGrow: 1 } }>
                      <Label>Weight Extra (kg)</Label>
                      <NumberFormat
                          name="weightExtra"
                          value={ order.weightExtra }
                          className="form-control text-end"
                          placeholder="0"
                          thousandSeparator={ true }
                          fixedDecimalScale={ true }
                          onValueChange={ values => {
                            const { floatValue } = values;
                            handleNumberFormatChange('weightExtra', floatValue);
                          } }
                      />
                    </FormGroup>
                  </Col>
                  <Col md={ 4 }>
                    <FormGroup className="mb-3" style={ { flexGrow: 1 } }>
                      <Label>Weight Total (kg)</Label>
                      <Input
                          type="number"
                          name="weight"
                          className="text-end"
                          placeholder="Weight"
                          value={ Number(order.weight ?? 0) + Number(order.weightExtra ?? 0) }
                          readOnly
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={ 4 }>
                    <FormGroup className="mb-3" style={ { flexGrow: 1 } }>
                      <Label>Comment</Label>
                      <Input
                          type="text"
                          name="weightComment"
                          placeholder="Weight Comment"
                          value={ order.weightComment }
                          onChange={ handleInputOnChange }
                      />
                    </FormGroup>
                  </Col>
                  <Col md={ 4 }>
                    <FormGroup className="mb-3">
                      <Label>Rate</Label>
                      <NumberFormat
                          name="weightRate"
                          value={ Number(order.weightRate).toFixed(0) }
                          className="form-control text-end"
                          thousandSeparator={ true }
                          fixedDecimalScale={ false }
                          readOnly
                      />
                    </FormGroup>
                  </Col>
                  <Col md={ 4 }>
                    <FormGroup className="mb-3">
                      <Label>Amount</Label>
                      <NumberFormat
                          name="weightFeeAmount"
                          value={ order.weightFeeAmount }
                          className="form-control text-end"
                          thousandSeparator={ true }
                          fixedDecimalScale={ false }
                          prefix={ `${ currencyLabel } ` }
                          readOnly
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col md={ 3 }>
            <Card>
              <CardBody>
                <CardTitle>Unit Fee</CardTitle>
                <Row>
                  <Col md={ 12 }>
                    <FormGroup className="mb-3" style={ { flexGrow: 1 } }>
                      <Label>Unit Fee (KRW)</Label>

                      <NumberFormat
                          name="unitFee"
                          value={ order.unitFee }
                          className="form-control text-end"
                          placeholder="0"
                          thousandSeparator={ true }
                          fixedDecimalScale={ false }
                          onValueChange={ values => {
                            const { floatValue } = values;
                            handleNumberFormatChange('unitFee', floatValue);
                          } }
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col md={ 12 }>
            <Card>
              <CardBody>
                <CardTitle>Additional Services</CardTitle>
                { order.orderAdditionalEntries &&
                    order.orderAdditionalEntries.map((a, index) => {
                      return (
                          <OrderAdditionalEntryForm
                              key={ index }
                              item={ a }
                              isPrimary={ index === 0 }
                              onAdd={ handleOnAdd }
                              onRemove={ handleOnRemove }
                              onChange={ handleOnChange }
                          />
                      );
                    }) }
              </CardBody>
            </Card>
          </Col>
          <Col md={ 3 }>
            <Card>
              <CardBody>
                <CardTitle>Pickup</CardTitle>
                <Row>
                  <Col md={ 12 }>
                    <div className="form-check form-check-primary mb-3">
                      <input
                          type="checkbox"
                          className="form-check-input"
                          id="isPickup"
                          name={ "isPickup" }
                          checked={ order.isPickup }
                          onChange={ handleCheckboxOnChange }
                      />
                      <label
                          className="form-check-label"
                          htmlFor="customCheckcolor1"
                      >
                        Is Pickup ?
                      </label>
                    </div>
                    <FormGroup className="mb-3">
                      <Label>Pickup Fee (KRW)</Label>
                      <NumberFormat
                          name="pickupFee"
                          readOnly={ !order.isPickup }
                          value={ order.pickupFee }
                          className="form-control text-end"
                          placeholder="0"
                          onValueChange={ values => {
                            const { floatValue } = values;
                            handleNumberFormatChange('pickupFee', floatValue);
                          } }
                          thousandSeparator={ true }
                          fixedDecimalScale={ false }
                          prefix={ `${ currencyLabel } ` }
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col md={ 3 }>
            <Card>
              <CardBody>
                <CardTitle>Delivery</CardTitle>
                <Row>
                  <Col md={ 12 }>
                    <div className="form-check form-check-primary mb-3">
                      <input
                          type="checkbox"
                          className="form-check-input"
                          id="isDelivery"
                          name={ "isDelivery" }
                          checked={ order.isDelivery }
                          onChange={ handleCheckboxOnChange }
                      />
                      <label
                          className="form-check-label"
                          htmlFor="customCheckcolor1"
                      >
                        Is Delivery ?
                      </label>
                    </div>
                    <FormGroup className="mb-3">
                      <Label>Delivery Fee (KRW)</Label>
                      <NumberFormat
                          name="deliveryFee"
                          readOnly={ !order.isDelivery }
                          value={ order.deliveryFee }
                          className="form-control text-end"
                          placeholder="0"
                          onValueChange={ values => {
                            const { floatValue } = values;
                            handleNumberFormatChange('deliveryFee', floatValue);
                          } }
                          thousandSeparator={ true }
                          fixedDecimalScale={ false }
                          prefix={ `${ currencyLabel } ` }
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col md={ 3 }>
            <Card>
              <CardBody>
                <CardTitle>Discount</CardTitle>
                <Row>
                  <Col md={ 12 }>
                    <FormGroup className="mb-3">
                      <Label>Percentage</Label>
                      <NumberFormat
                          name="discountPercentage"
                          value={ order.discountPercentage }
                          className="form-control text-end"
                          fixedDecimalScale={ false }
                          placeholder="% 0"
                          onValueChange={ values => {
                            const { floatValue } = values;
                            handleNumberFormatChange('discountPercentage', floatValue);
                          } }
                          prefix={ `% ` }
                      />
                    </FormGroup>
                    <FormGroup className="mb-3">
                      <Label>Amount</Label>
                      <NumberFormat
                          name="discountAmount"
                          value={ order.discountAmount }
                          className="form-control text-end"
                          thousandSeparator={ true }
                          fixedDecimalScale={ false }
                          readOnly
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col md={ 3 }>
            <Card>
              <CardBody>
                <CardTitle>Total Fee</CardTitle>
                <Row>
                  <Col md={ 12 }>
                    <FormGroup className="mb-3">
                      <Label>Total Amount (KRW)</Label>
                      <NumberFormat
                          name="totalAmount"
                          readOnly
                          value={ Number(order.totalAmount).toFixed(0) }
                          className="form-control text-end"
                          thousandSeparator={ true }
                          fixedDecimalScale={ false }
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col md={ 12 }>
            <Card>
              <CardBody>
                <div className="float-end">
                  { hasAccess(permission.order.write) && (
                      <Button color="primary" onClick={ handleSubmit }>
                        Submit{ " " }
                      </Button>
                  ) }{ " " }
                  <Button color="primary" onClick={ handleCancel } outline>
                    Cancel
                  </Button>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <UserSelectionModalList
            title={ "Find Sender" }
            isOpen={ modalSelectSender }
            toggle={ () => setModalSelectSender(!modalSelectSender) }
            onSubmit={ handleSelectSenderUser }
        />

        <UserSelectionModalList
            title={ "Find Reciever" }
            isOpen={ modalSelectReceiver }
            toggle={ () => setModalSelectReceiver(!modalSelectReceiver) }
            onSubmit={ handleSelectReceiverUser }
        />

        <SelectionListModal
            title={ "Find Shipping Contact" }
            userId={ order.receiverId?.value ?? null }
            isOpen={ modalSelectionShippingAddress }
            toggle={ () =>
                setModalSelectionShippingAddress(!modalSelectionShippingAddress)
            }
            onSubmit={ handleSubmitSelectionShippingAddress }
        />

        <ModalFormShippingContact
            title={ "Add New Shipping Contact" }
            contact={ order.contact }
            userId={ order.receiverId?.value ?? null }
            isOpen={ modalAddShippingAddress }
            toggle={ () => setModalAddShippingAddress(!modalAddShippingAddress) }
            onSubmit={ hanldeSubmitShippingAddress }
        />
      </>
  );
};

OrderForm2.propTypes = {};

export default OrderForm2;