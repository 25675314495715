import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Container,
  Button,
  Card,
  CardBody,
  Input,
  FormGroup,
  CardHeader,
  Row, Col
} from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getSaleOrderInvoiceReport} from "../../store/sale-order-invoice-report/actions";
import MetaTags from "react-meta-tags";
import { displayInvoicePaymentStatus, getCompanyName } from "../../helpers/utils";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import NumberFormat from "react-number-format";
import { Table } from "react-super-responsive-table";
import moment from "moment";
import CustomPagination from "../../components/Common/CustomPagination";
import { debounce } from "lodash";
import { saleOrderInvoiceReportService } from "services/sale-order-invoice-rpt-service";

const SaleOrderInvoiceReport = props => {
  const { getSaleOrderInvoiceReport, saleOrderInvoiceReport } = props;
  const { 
    items, 
    itemCount,
    pageCount,
    loading, 
    totalAmount,
    totalOutstanding,
    totalPayment
  } = saleOrderInvoiceReport;
  
  const [ page, setPage ] = useState(1);
  const [ pageSize, setPageSize ] = useState(10);
  const [ startDate, setStartDate ] = useState(null)
  const [ endDate, setEndDate ] = useState(null);
  
  const [ term, setTerm ] = useState('');

  const debouncedFetchData = useCallback(
    debounce(term => {
      setTerm(term);
    }, 1000),
    []
  );
  
  useEffect(() => {
    getSaleOrderInvoiceReport({
      term,
      page,
      pageSize,
      startDate: startDate
        ? moment(startDate).format("YYYY-MM-DDTHH:mm:ss.SSS")
        : null,
      endDate: endDate ? moment(endDate).format("YYYY-MM-DDTHH:mm:ss.SSS") : null,
    })
  }, [term, page, pageSize, startDate, endDate]);

  const handleExportToExcel = () => {
    saleOrderInvoiceReportService.exportToExcelRequest(
     {},
      `${moment().format("YYYYMMDD_HHmmss")}_Sale_Invoice_Report_List.xlsx`
    );
  };
  
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Invoice Report | {getCompanyName()}</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Home" breadcrumbItem="Invoice Report" />
          <Row>
            <Col md={12}>
              <Card className="mb-2 rounded-2">
                <CardHeader className="bg-transparent border pb-0 pt-3">
                  <Row className="g-1">
                    <Col md={2}>
                      <div className="search-box me-2 mb-2 d-inline-block">
                        <div className="position-relative">
                          <Input
                            type="text"
                            placeholder="Quick Search ..."
                            onChange={e =>{
                              debouncedFetchData(e.target.value);
                            }}
                          ></Input>
                          <i className="fas fa-search search-icon" />
                        </div>
                      </div>
                    </Col>
                    <Col md={2}>
                      <FormGroup>
                        <DatePicker
                          className="form-control"
                          selectsStart
                          name="startDate"
                          selected={startDate}
                          startDate={startDate}
                          endDate={endDate}
                          onChange={date => setStartDate(date)}
                          dateFormat="dd-MMM-yyyy"
                          placeholderText="Start Date"
                          autoComplete="off"
                          isClearable
                        />
                      </FormGroup>
                    </Col>
                    <Col md={2}>
                      <FormGroup>
                        <DatePicker
                          className="form-control"
                          name="endDate"
                          selectsEnd
                          selected={endDate}
                          startDate={startDate}
                          endDate={endDate}
                          onChange={date =>setEndDate(date)}
                          dateFormat="dd-MMM-yyyy"
                          placeholderText="End Date"
                          autoComplete="off"
                          isClearable
                        />
                      </FormGroup>
                    </Col>
                    <Col></Col>
                    <Col md={4}>
                      <div className="text-sm-end">
                        <Button
                          type="button"
                          color="primary"
                          outline
                          size="md"
                          onClick={handleExportToExcel}
                        >
                          <i className="fas fa-file-excel" /> Export to Excel
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>
              </Card>
              {loading ? (
                <div className="d-flex justify-content-center">
                  <div
                    className="spinner-border text-primary m-1"
                    role="status"
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              ) : (
                <>
                  <Card className="mb-2">
                    <CardBody className="pb-0">
                      <Row>
                        <Col md={4}>
                          <Card
                            color="primary"
                            className="border border-primary mini-stats-wid"
                          >
                            <CardBody>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <p className="text-white fw-medium">
                                    Total Amount
                                  </p>
                                  <h4 className="mb-0 text-white">
                                    <NumberFormat
                                      value={totalAmount}
                                      thousandSeparator={true}
                                      fixedDecimalScale={true}
                                      displayType="text"
                                      prefix="$ "
                                    />
                                  </h4>
                                </div>
                                <div
                                  className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center"
                                  style={{
                                    borderColor: "white",
                                    borderStyle: "solid",
                                  }}
                                >
                                    <span className="avatar-title">
                                      <i
                                        className={
                                          "fas fa-dollar-sign font-size-24"
                                        }
                                      />
                                    </span>
                                </div>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                        <Col md={4}>
                          <Card
                            color="success"
                            className="border border-success mini-stats-wid"
                          >
                            <CardBody>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <p className="text-white fw-medium">
                                    Total Payment
                                  </p>
                                  <h4 className="mb-0 text-white">
                                    <NumberFormat
                                      value={totalPayment}
                                      thousandSeparator={true}
                                      fixedDecimalScale={true}
                                      displayType="text"
                                      prefix="$ "
                                    />
                                  </h4>
                                </div>
                                <div
                                  className="mini-stat-icon avatar-sm rounded-circle bg-success align-self-center"
                                  style={{
                                    borderColor: "white",
                                    borderStyle: "solid",
                                  }}
                                >
                                    <span className="avatar-title bg-success">
                                      <i
                                        className={"fas fa-check font-size-24"}
                                      />
                                    </span>
                                </div>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                        <Col md={4}>
                          <Card
                            color="warning"
                            className="border border-warning mini-stats-wid"
                          >
                            <CardBody>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <p className="text-white fw-medium">
                                    Balance Due
                                  </p>
                                  <h4 className="mb-0 text-white">
                                    <NumberFormat
                                      value={totalOutstanding}
                                      thousandSeparator={true}
                                      fixedDecimalScale={true}
                                      displayType="text"
                                      prefix="$ "
                                    />
                                  </h4>
                                </div>
                                <div
                                  className="mini-stat-icon avatar-sm rounded-circle bg-success align-self-center"
                                  style={{
                                    borderColor: "white",
                                    borderStyle: "solid",
                                  }}
                                >
                                    <span className="avatar-title bg-warning">
                                      <i
                                        className={"bx bx-list-ol font-size-24"}
                                      />
                                    </span>
                                </div>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>

                  <Card>
                    <CardBody>
                      <div className="table-rep-plugin">
                        {loading ? (
                          <div className="d-flex justify-content-center">
                            <div className="spinner-border text-primary m-1" role="status">
                              <span className="sr-only">Loading...</span>
                            </div>
                          </div>
                        ) : (
                          <div
                            className="table-responsive mb-0"
                            data-pattern="priority-columns"
                          >
                            <Table
                              id="tech-companies-1"
                              className="table table-striped table-bordered"
                            >
                              <thead>
                              <tr>
                                <th className="text-center">#</th>
                                <th className="text-center">Invoice No</th>
                                <th className="text-center">Order No</th>
                                <th className="text-center">Invoice Date</th>
                                <th className="text-center">Amount</th>
                                <th className="text-center">Payment</th>
                                <th className="text-center">Outstanding</th>
                                <th className="text-center">Payment Status</th>
                              </tr>
                              </thead>
                              <tbody>
                              {items.map((item, index) => {
                                let num =
                                  (page - 1) * pageSize + (index + 1);
                                return (
                                  <tr
                                    key={ index }
                                    style={ { cursor: "pointer" } }
                                  >
                                    <td>{ num }</td>
                                    <td>
                                      { item.invoiceNo }
                                    </td>
                                    <td>
                                      { item.orderNo }
                                    </td>
                                    <td className="text-end">
                                      { moment
                                        .utc(item.date)
                                        .local()
                                        .format("DD-MMM-yyyy") }
                                    </td>
                                    <td className="text-end">
                                      <NumberFormat
                                        value={ item.amount }
                                        thousandSeparator={ true }
                                        fixedDecimalScale={ false }
                                        displayType="text"
                                      />
                                    </td>
                                    <td className="text-end">
                                      <NumberFormat
                                        value={ item.totalPayment }
                                        thousandSeparator={ true }
                                        fixedDecimalScale={ false }
                                        displayType="text"
                                      />
                                    </td>
                                    <td className="text-end">
                                      <NumberFormat
                                        value={ item.outstanding }
                                        thousandSeparator={ true }
                                        fixedDecimalScale={ false }
                                        displayType="text"
                                      />
                                    </td>

                                    <td className="text-center">
                                      { displayInvoicePaymentStatus(item) }
                                    </td>
                                  </tr>
                                );
                              }) }
                              </tbody>
                            </Table>
                          </div>
                        )}
                      </div>
                      <CustomPagination
                        itemCount={itemCount}
                        currentPage={page}
                        totalPage={pageCount}
                        defaultPageSize={pageSize}
                        pageSizeOptions={[5, 10, 20, 30, 40, 50, 100]}
                        onChangePage={i => setPage(i)}
                        onPageSizeChange={size => {
                          setPage(1);
                          setPageSize(size);
                        }}
                      />
                    </CardBody>
                  </Card>
                </>
              )}
            </Col>
          </Row>

        </Container>
      </div>
    </React.Fragment>
  );
};

SaleOrderInvoiceReport.propTypes = {
  
};

const mapStateToProps = ({ saleOrderInvoiceReport }) => {
  return {
    saleOrderInvoiceReport
  }
};

export default withRouter(
  connect(mapStateToProps, {
    getSaleOrderInvoiceReport
  })(SaleOrderInvoiceReport)
);

