import React from "react";
import { Redirect } from "react-router-dom";
// User profile
import UserProfile from "../pages/Authentication/UserProfile";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import RoleList from "../pages/UserManagement/Role/role-list";
import RoleDetail from "pages/UserManagement/Role/RoleDetail";
import UserList from "../pages/UserManagement/User/user-list";
import UserDetail from "../pages/UserManagement/User/UserDetail";

import UnitList from "../pages/Inventory/Unit/unit-list";
import OrderList from "../pages/Order/OrderList";
import AddNewOrder from "../pages/Order/AddNewOrder";
import OrderDetail from "../pages/Order/OrderDetail";

import WarehouseList from "pages/Inventory/Warehouse/warehouse-list";
import CategoryList from "pages/Inventory/Category/category-list";
import AttributeList from "pages/Inventory/Attribute/attribute-list";
import CurrencyList from "pages/Inventory/Currency/currency-list";
import BannerList from "pages/Inventory/Banner/banner-list";

// Dashboard
import Dashboard from "../pages/Dashboard/index";
import Settings from "../pages/Settings/index";
import ShippingFee from "../pages/ShippingFee/index";
import ShippingFeeDetail from "../pages/ShippingFee/detail";
import PermissionList from "../pages/UserManagement/permission/permission-list";
import TransportList from "../pages/Transport/TransportList";
import TransportDetail from "../pages/Transport/TransportDetail";
import AddNewTransport from "../pages/Transport/AddNewTransport";
import InvoiceList from "pages/Invoice/InvoiceList";
import InvoiceDetail from "pages/Invoice/InvoiceDetail";
import ReceiveOrderList from "pages/ReceiveOrder/ReceiveOrderList";
import InvoiceNoteTab from "pages/Invoice/InvoiceNoteTab";
import InvoiceReport from "pages/Reports/InvoiceReport";
import PaymentHistoryList from "pages/InvoicePaymentHistory/PaymentHistoryList";
import ReceiveOrderDetail from "pages/ReceiveOrder/ReceiveOrderDetail";
import OrderVerifyTrackingList from "pages/OrderVerifyTracking/OrderVerifyTrackingList";
import DeliveryAgencyList from "pages/DeliveryAgency/DeliveryAgencyList";
import DelivererList from "pages/Deliverer/DelivererList";
import SupplierList from "pages/Supplier/SupplierList";
import DeliveryOrderList from "pages/DeliveryOrder/DeliveryOrderList";
import DeliveryList from "pages/Delivery/DeliveryList";
import AddNewDelivery from "pages/Delivery/AddNewDelivery";
import DeliveryDetail from "pages/Delivery/DeliveryDetail";
import AddNewDeliveryOrder from "pages/DeliveryOrder/AddNewDeliveryOrder";
import DeliveryOrderDetail from "pages/DeliveryOrder/DeliveryOrderDetail";
import OrderReport from "pages/Reports/OrderReport";
import SendOrderReport from "pages/Reports/SendOrderReport";
import ReceiveOrderReport from "pages/Reports/ReceiveOrderReport";
import DeliveryOrderReport from "pages/Reports/DeliveryOrderReport";
import DeviceInfoList from "pages/DeviceInfo/DeviceInfoList";
import DeviceInfoDetail from "pages/DeviceInfo/DeviceInfoDetail";
import FeedbackList from "pages/Feedback/FeedbackList";
import FeedbackDetail from "pages/Feedback/FeedbackDetail";
import AlertNotificationList from "pages/AlertNotification/AlertNotificationList";
import AlertNotificationDetail from "pages/AlertNotification/AlertNotificationDetail";
import RequestedDeliveryList from "pages/RequestedDelivery/RequestedDeliveryList";
import PickupList from "pages/Pickup/PickupList";
import PickupDetail from "pages/Pickup/PickupDetail";
import DeliveryToDoorList from "../pages/DeliveryToDoor/DeliveryToDoorList";
import DoorToDoorInvoiceList from "../pages/DoorToDoorInvoice/DoorToDoorInvoiceList";
import DoorToDoorInvoicePaymentHistoryList  from "../pages/DoorToDoorInvoice/DoorToDoorInvoicePaymentHistoryList";
import DoorToDoorInvoiceDetail  from "../pages/DoorToDoorInvoice/DoorToDoorInvoiceDetail";
import UomList from "../pages/Uom/UomList";
import TagList from "../pages/Tag/TagList";
import ProductList from "../pages/Product/ProductList";
import addNewProduct from "../pages/Product/AddNewProduct";
import ProductDetail from "../pages/Product/ProductDetail";
import StockInList from "../pages/StockIn/StockInList";
import AddNewStockIn from "../pages/StockIn/AddNewStockIn";
import StockInDetail from "../pages/StockIn/StockInDetail";
import StockOutDetail from "../pages/StockOut/StockOutDetail";
import AddNewStockOut from "../pages/StockOut/AddNewStockOut";
import StockOutList from "../pages/StockOut/StockOutList";
import StockAdjustmentList from "../pages/StockAdjustment/StockAdjustmentList";
import ProductTransactionList from "pages/Transaction/ProductTransactionList";
import StockAdjustmentDetail from "../pages/StockAdjustment/StockAdjustmentDetail";
import AddNewStockAdjustment from "../pages/StockAdjustment/AddNewStockAdjustment";
import SaleOrderList from "../pages/SaleOrder/SaleOrderList";
import AddNewSaleOrder from "../pages/SaleOrder/AddNewSaleOrder";
import SaleOrderDetail from "../pages/SaleOrder/SaleOrderDetail";
import SaleOrderInvoiceList from "../pages/SaleOrderInvoice/SaleOrderInvoiceList";
import SaleOrderInvoiceDetail from "../pages/SaleOrderInvoice/SaleOrderInvoiceDetail";
import VehicleList from "../pages/Vehicle/VehicleList";
import DiscountPromotionList from "../pages/DiscountPromotion/DiscountPromotionList";
import AddNewDiscountPromotion from "../pages/DiscountPromotion/AddNewDiscountPromotion";
import DiscountPromotionDetail from "../pages/DiscountPromotion/DiscountPromotionDetail";
import WalletList from "../pages/Wallet/WalletList"
import AddNewWallet from "../pages/Wallet/AddNewWallet";
import WalletDetail from "../pages/Wallet/WalletDetail";
import DeliveryItemList from "../pages/DeliveryItem/DeliveryItemList"
import SaleOrderInvoiceReport from "../pages/Reports/SaleOrderInvoiceReport";
import ProductStockReport from "pages/Reports/ProductReport";


const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },

  //profile
  { path: "/profile", component: UserProfile },

  { path: "/pickup", component: PickupList },
  { path: "/pickup/:id", component: PickupDetail },

  { path: "/wallet", component: WalletList },
  { path: "/wallet/add-new", components: AddNewWallet},
  { path: "/wallet/:id", component: WalletDetail },

  { path: "/order", component: OrderList },
  { path: "/order-verify-tracking", component: OrderVerifyTrackingList },
  { path: "/order/add-new", component: AddNewOrder },
  { path: "/order/:id", component: OrderDetail },

  { path: "/transport", component: TransportList },
  { path: "/transport/add-new", component: AddNewTransport },
  { path: "/transport/:id", component: TransportDetail },
  { path: "/receive-order", component: ReceiveOrderList },
  { path: "/receive-order/:id", component: ReceiveOrderDetail },

  { path: "/user", component: UserList },
  { path: "/user/:id", component: UserDetail },
  { path: "/user/:id/:activeTab", component: UserDetail },
  { path: "/role", component: RoleList },
  { path: "/role/:id", component: RoleDetail },
  { path: "/inventory/unit", component: UnitList },
  { path: "/inventory/warehouse", component: WarehouseList },
  { path: "/inventory/category", component: CategoryList },
  { path: "/inventory/attribute", component: AttributeList },
  { path: "/setting", component: Settings },
  { path: "/shipping-fee", component: ShippingFee },
  { path: "/shipping-fee/:id", component: ShippingFeeDetail },
  { path: "/currency", component: CurrencyList },
  { path: "/inventory/banner", component: BannerList },
  { path: "/permission", component: PermissionList },

  { path: "/delivery-agency", component: DeliveryAgencyList },
  { path: "/deliverer", component: DelivererList },
  { path: "/supplier", component: SupplierList },
  { path: "/delivery-order", component:  DeliveryOrderList},
  { path: "/delivery-order/add-new", component: AddNewDeliveryOrder },
  { path: "/delivery-order/:id", component: DeliveryOrderDetail },
  { path: "/requested-delivery", component: RequestedDeliveryList },
  { path: "/delivery-to-door", component: DeliveryToDoorList },

  { path: "/delivery", component: DeliveryList },
  { path: "/delivery/add-new", component: AddNewDelivery },
  { path: "/delivery/:id", component: DeliveryDetail },

  { path: "/delivery-item", component: DeliveryItemList },

  { path: "/door-to-door-invoice", component: DoorToDoorInvoiceList },
  { path: "/door-to-door-invoice/:id", component: DoorToDoorInvoiceDetail },
  { path: "/door-to-door-invoice-payment-history", component: DoorToDoorInvoicePaymentHistoryList },

  { path: "/invoice", component: InvoiceList },
  { path: "/invoice/:id", component: InvoiceDetail },
  { path: "/invoice/:id/:userId", component: InvoiceDetail },
  { path: "/invoiceNote", component: InvoiceNoteTab },

  { path: "/invoice-payment-history", component: PaymentHistoryList },
  { path: "/feedback", component: FeedbackList },
  { path: "/feedback/:id", component: FeedbackDetail },

  { path: "/device-info", component: DeviceInfoList },
  { path: "/device-info/:id", component: DeviceInfoDetail },
  { path: "/device-info/:id/:userId", component: DeviceInfoDetail },

  { path: "/alert-notification", component: AlertNotificationList },
  { path: "/alert-notification/:id", component: AlertNotificationDetail },

  { path: "/product", component: ProductList },
  { path: "/product/add-new", component: addNewProduct },
  { path: "/product/:id", component: ProductDetail },

  { path: "/stock-in", component: StockInList },
  { path: "/stock-in/add-new", component: AddNewStockIn },
  { path: "/stock-in/:id", component: StockInDetail },

  { path: "/stock-out", component: StockOutList },
  { path: "/stock-out/add-new", component: AddNewStockOut },
  { path: "/stock-out/:id", component: StockOutDetail },

  { path: "/stock-adjustment", component: StockAdjustmentList },
  { path: "/stock-adjustment/add-new", component: AddNewStockAdjustment },
  { path: "/stock-adjustment/:id", component: StockAdjustmentDetail },

  { path: "/product-transaction", component: ProductTransactionList },

  { path: "/sale-order", component: SaleOrderList },
  { path: "/sale-order/add-new", component: AddNewSaleOrder },
  { path: "/sale-order/:id", component: SaleOrderDetail },

  { path: "/sale-order-invoice", component: SaleOrderInvoiceList },
  { path: "/sale-order/add-new", component: AddNewSaleOrder },
  { path: "/sale-order-invoice/:id", component: SaleOrderInvoiceDetail },

  { path: "/uom", component: UomList },
  { path: "/vehicle", component: VehicleList },
  { path: "/tag", component: TagList },

  { path: "/discount-promotion", component: DiscountPromotionList },
  { path: "/discount-promotion/add-new", component: AddNewDiscountPromotion },
  { path: "/discount-promotion/:id", component: DiscountPromotionDetail },

  // Reports
  { path: "/invoice-report", component: InvoiceReport },
  { path: "/sale-order-invoice-report", component: SaleOrderInvoiceReport },
  { path: "/product-stock-report", component: ProductStockReport },
  { path: "/order-report", component: OrderReport },
  { path: "/send-order-report", component: SendOrderReport },
  { path: "/receive-order-report", component: ReceiveOrderReport },
  { path: "/delivery-order-report", component: DeliveryOrderReport },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
];

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
];

export { authProtectedRoutes, publicRoutes };
