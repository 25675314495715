import { call, put, takeEvery } from "redux-saga/effects";
import { GET_SALE_ORDER_INVOICE_REPORT } from "./actionTypes";
import { get, post } from "../../helpers/api_helper";
import { getSaleOrderInvoiceReportSuccess, getSaleOrderInvoiceReportFail } from "./actions";

const getSaleOrderInvoiceReportRequest = query =>
  post(`/api/SaleOrderInvoice/report`, query);

function* getSaleOrderInvoiceReport({ payload }) {
  try {
    console.log(payload);
    const response = yield call(getSaleOrderInvoiceReportRequest, payload);

    yield put(getSaleOrderInvoiceReportSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getSaleOrderInvoiceReportFail(error));
  }
}

export default function* rootSaga() {
  yield takeEvery(GET_SALE_ORDER_INVOICE_REPORT, getSaleOrderInvoiceReport);
}

