import CustomPagination from "components/Common/CustomPagination";

import PropTypes from "prop-types";
import React, { Component } from "react";
import { Link } from "react-router-dom";

import { Card, CardBody, Table } from "reactstrap";

export class DeviceInfoTable extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const {
      itemCount,
      currentPage,
      totalPage,
      defaultPageSize,
      items,
      loading,
      showUserInfo,
      onChangePage,
      onPageSizeChange,
      onViewDetail,
      onSendMessage
    } = this.props;

    return (
      <Card>
        <CardBody>
          <div className="table-rep-plugin">
            {loading ? (
              <div className="d-flex justify-content-center">
                <div className="spinner-border text-primary m-1" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              <div className="table-responsive" data-pattern="priority-columns">
                <Table
                  id="tech-companies-1"
                  className="table table-striped table-bordered table-nowrap align-middle"
                >
                  <thead>
                    <tr>
                      <th>No</th>
                      {showUserInfo && (
                        <>
                          <th>User Id</th>
                          <th>User</th>
                        </>
                      )}
                      <th>Brand</th>
                      <th>Device ID</th>
                      <th>Model</th>
                      <th>System Version</th>
                      <th style={{ width: "120px" }}>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {items.map((item, index) => {
                      let num =
                        (currentPage - 1) * defaultPageSize + (index + 1);
                      return (
                        <tr
                          key={index}
                          style={{ cursor: "pointer" }}
                          onDoubleClick={() => onViewDetail(item.id)}
                        >
                          <td className="text-end">{num}</td>
                          {showUserInfo && (
                            <>
                              <td>{item.uniqueId}</td>
                              <td>
                                {item.firstName} {item.lastName}
                              </td>
                            </>
                          )}
                          <td>{item.brand}</td>
                          <td>{item.deviceId}</td>
                          <td>{item.model}</td>
                          <td>{item.systemVersion}</td>
                          <td>
                            <div className="text-center">
                              <Link className="px-2" to="#">
                                <i
                                  className="far fa-envelope font-size-18"
                                  onClick={() => onSendMessage(item)}
                                ></i>
                              </Link>
                              <Link className="text-primary" to="#">
                                <i
                                  className="far fa-list-alt font-size-18"
                                  onClick={() => onViewDetail(item.id)}
                                ></i>
                              </Link>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            )}
          </div>
          <CustomPagination
            itemCount={itemCount}
            currentPage={currentPage}
            totalPage={totalPage}
            defaultPageSize={defaultPageSize}
            pageSizeOptions={[5, 10, 20, 30, 40, 50, 100]}
            onChangePage={i => onChangePage(i)}
            onPageSizeChange={size => onPageSizeChange(size)}
          />
        </CardBody>
      </Card>
    );
  }
}

DeviceInfoTable.propTypes = {
  itemCount: PropTypes.number,
  currentPage: PropTypes.number,
  totalPage: PropTypes.number,
  defaultPageSize: PropTypes.number,
  items: PropTypes.array,
  showUserInfo: PropTypes.bool,
  loading: PropTypes.bool,

  onChangePage: PropTypes.func,
  onPageSizeChange: PropTypes.func,
  onViewDetail: PropTypes.func,
  onSendMessage: PropTypes.func,
};

export default DeviceInfoTable;
