import { all, fork } from "redux-saga/effects";

//public
import AccountSaga from "./auth/register/saga";
import AuthSaga from "./auth/login/saga";
import ForgetSaga from "./auth/forgetpwd/saga";
import ProfileSaga from "./auth/profile/saga";
import LayoutSaga from "./layout/saga";
import calendarSaga from "./calendar/saga";
import chatSaga from "./chat/saga";
import cryptoSaga from "./crypto/saga";
import projectsSaga from "./projects/saga";
import tasksSaga from "./tasks/saga";
import mailsSaga from "./mails/saga";
import contactsSaga from "./contacts/saga";
import dashboardSaga from "./dashboard/saga";
import dashboardSaasSaga from "./dashboard-saas/saga";
import roleSaga from "./role/saga";
import unitSaga from "./unit/saga";
import warehouseSaga from "./warehouse/saga";
import orderSaga from "./order/saga";
import categorySaga from "./category/saga";
import attributeSaga from "./attribute/saga";
import userSaga from "./user/saga";
import orderItemSaga from "./order-item/saga";
import currencySaga from "./currency/saga";
import bannerSaga from "./banner/saga";
import itemShippingFeeSaga from "./item-shipping-fee/saga";
import itemShippingFeeEntrySaga from "./item-shipping-fee-entry/saga";
import userItemShippingFeeSaga from "./user-item-shipping-fee/saga";
import shippingAddressSaga from "./shipping-contact/saga";
import permissionSaga from "./permission/saga";
import orderAttachmentSaga from "./order-attachment/saga";
import orderNoteSaga from "./order-note/saga";
import transportSaga from "./transport/saga";
import transportOrderSaga from "./transport-order/saga";
import transportNoteSaga from "./transport-note/saga";
import orderTrackingSaga from "./order-tracking/saga";
import invoiceSaga from "./invoice/saga";
import invoicePaymentSaga from "./invoice-payment/saga";
import invoiceNoteSaga from "./invoice-note/saga";
import invoicePaymentHistorySaga from "./invoice-payment-history/saga";
import invoiceReportSaga from "./invoice-report/saga";
import settingSaga from "./settings/saga";
import deliveryAgencySaga from "./delivery-agency/saga";
import delivererSaga from "./deliverer/saga";
import SupplierSaga from "./supplier/saga";
import deliveryOrderSaga from "./delivery-order/saga";
import deliverySaga from "./delivery/saga";
import deliveryOrderItemSaga from "./delivery-order-item/saga";
import deliveryItemSaga from "./delivery-item/saga";
import orderVerifyTrackingSaga from "./order-verify-tracking/saga";
import orderNotificationHistorySaga from "./order-notification-history/saga";
import orderMessageHistorySaga from "./order-message-history/saga";
import deviceInfoSaga from "./device-info/saga";
import deviceInfoNotificationSaga from "./device-info-notification-history/saga";
import feedbackSaga from "./feedback/saga";
import alertNotificationSaga from "./alert-notification/saga";
import alertNotificationHistorySaga from "./alert-notification-history/saga";
import pickupSaga from "./pickup/saga";
import pickupAttachmentSaga from "./pickup-attachment/saga";
import walletSage from "./wallet/saga";
import walletTransactionSaga from "./wallet-transaction/saga";
import deliveryToDoorSaga from "./delivery-to-door/saga";
import caseAuditSaga from "./case-audit/saga";
import uomSaga from "./uom/saga";
import tagSaga from "./tag/saga";
import productSaga from "./product/saga";
import productImageSaga from "./product-image/saga";
import productUomSaga from "./product-uom/saga";
import stockInSaga from "./stock-in/saga";
import stockOutSaga from "./stock-out/saga";
import stockAdjustmentSaga from "./stock-adjustment/saga";
import saleOrderSaga from "./sale-order/saga";
import saleOrderCommentSaga from "./sale-order-comment/saga";
import saleOrderInvoiceSaga from "./sale-order-invoice/saga";
import saleOrderInvoicePaymentSaga from "./sale-order-invoice-payment/saga";
import productReviewSaga from "./product-review/saga";
import productTransactionSaga from "./product-transaction/saga"
import vehicleSaga from "./vehicle/saga";
import userDocumentSaga from "./user-document/saga";
import userVehicleSaga from "./user-vehicle/saga";
import orderDocumentSaga from "./order-document/saga";
import discountPromotionSaga from "./discount-promotion/saga";
import discountPromotionProductSaga from "./discount-promotion-product/saga";
import saleOrderInvoiceReportSaga from "./sale-order-invoice-report/saga";
import productReportSaga from "./product-report/saga";

export default function* rootSaga() {
  yield all([
    fork(AccountSaga),
    fork(AuthSaga),
    fork(ForgetSaga),
    fork(ProfileSaga),
    fork(LayoutSaga),
    fork(calendarSaga),
    fork(chatSaga),
    fork(mailsSaga),
    fork(cryptoSaga),
    fork(projectsSaga),
    fork(tasksSaga),
    fork(contactsSaga),
    fork(dashboardSaga),
    fork(dashboardSaasSaga),
    fork(roleSaga),
    fork(unitSaga),
    fork(warehouseSaga),
    fork(orderSaga),
    fork(categorySaga),
    fork(attributeSaga),
    fork(userSaga),
    fork(orderItemSaga),
    fork(currencySaga),
    fork(bannerSaga),
    fork(itemShippingFeeSaga),
    fork(itemShippingFeeEntrySaga),
    fork(userItemShippingFeeSaga),
    fork(shippingAddressSaga),
    fork(permissionSaga),
    fork(orderAttachmentSaga),
    fork(orderNoteSaga),
    fork(transportSaga),
    fork(transportOrderSaga),
    fork(transportNoteSaga),
    fork(orderTrackingSaga),
    fork(invoiceSaga),
    fork(invoiceNoteSaga),
    fork(invoicePaymentSaga),
    fork(invoicePaymentHistorySaga),
    fork(invoiceReportSaga),
    fork(settingSaga),
    fork(deliveryAgencySaga),
    fork(delivererSaga),
    fork(SupplierSaga),
    fork(deliveryOrderSaga),
    fork(deliverySaga),
    fork(deliveryOrderItemSaga),
    fork(deliveryItemSaga),
    fork(orderVerifyTrackingSaga),
    fork(orderNotificationHistorySaga),
    fork(orderMessageHistorySaga),
    fork(deviceInfoSaga),
    fork(deviceInfoNotificationSaga),
    fork(feedbackSaga),
    fork(alertNotificationSaga),
    fork(alertNotificationHistorySaga),
    fork(pickupSaga),
    fork(pickupAttachmentSaga),
    fork(walletSage),
    fork(walletTransactionSaga),
    fork(deliveryToDoorSaga),
    fork(caseAuditSaga),
    fork(uomSaga),
    fork(tagSaga),
    fork(productSaga),
    fork(productImageSaga),
    fork(productUomSaga),
    fork(stockInSaga),
    fork(stockOutSaga),
    fork(stockAdjustmentSaga),
    fork(saleOrderSaga),
    fork(saleOrderCommentSaga),
    fork(saleOrderInvoiceSaga),
    fork(saleOrderInvoicePaymentSaga),
    fork(productReviewSaga),
    fork(productTransactionSaga),
    fork(vehicleSaga),
    fork(userDocumentSaga),
    fork(userVehicleSaga),
    fork(orderDocumentSaga),
    fork(discountPromotionSaga),
    fork(discountPromotionProductSaga),
    fork(saleOrderInvoiceReportSaga),
    fork(productReportSaga)
  ]);
}
