import { call, put, takeEvery } from "redux-saga/effects";
import {
  GET_DEVICE_INFO,
  ADD_NEW_DEVICE_INFO,
  GET_DEVICE_INFO_BY_ID,
  UPDATE_DEVICE_INFO,
  DELETE_DEVICE_INFO,
} from "./actionTypes";
import { get, post, del, put as update } from "../../helpers/api_helper";
import {
  getDeviceInfoSuccess,
  getDeviceInfoByIdSuccess,
  getDeviceInfoFail,
  addNewDeviceInfoSuccess,
  updateDeviceInfoSuccess,
  deleteDeviceInfoSuccess,
} from "./actions";

const getDeliveryAgenciesRequest = () => get(`/api/DeviceInfo`);
const getDeviceInfoRequest = query =>
  get(`/api/DeviceInfo/paging`, { params: query });

const addNewDeviceInfoRequest = item => post(`/api/DeviceInfo`, item);
const sendMessageToDeviceRequest = item => post(`/api/DeviceInfo/sendMessage`, item);
const getDeviceInfoByIdRequest = id => get(`/api/DeviceInfo/${id}`);
const updateDeviceInfoRequest = item => update(`/api/DeviceInfo`, item);
const deleteDeviceInfoRequest = id => del(`/api/DeviceInfo/${id}`);

function* fetchDeviceInfos({ payload }) {
  try {
    const response = yield call(getDeviceInfoRequest, payload);

    yield put(getDeviceInfoSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getDeviceInfoFail(error));
  }
}

function* addNewDeviceInfo({ payload }) {
  try {
    yield call(addNewDeviceInfoRequest, payload);
    const response = yield call(getDeviceInfoRequest);
    yield put(getDeviceInfoSuccess(response));
    yield put(addNewDeviceInfoSuccess(response));
  } catch (err) {
    console.log(err).response;
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getDeviceInfoFail(error));
  }
}

function* updateDeviceInfo({ payload }) {
  try {
    yield call(updateDeviceInfoRequest, payload);
    const response = yield call(getDeviceInfoRequest);
    yield put(getDeviceInfoSuccess(response));
    yield put(updateDeviceInfoSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getDeviceInfoFail(error));
  }
}

function* getDeviceInfoById({ payload }) {
  try {
    var response = yield call(getDeviceInfoByIdRequest, payload);
    yield put(getDeviceInfoByIdSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getDeviceInfoFail(error));
  }
}

function* deleteDeviceInfo({ payload }) {
  try {
    yield call(deleteDeviceInfoRequest, payload);
    const response = yield call(getDeviceInfoRequest);
    yield put(getDeviceInfoSuccess(response));
    yield put(deleteDeviceInfoSuccess(response));
  } catch (error) {
    const err = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getDeviceInfoFail(error));
  }
}

function* DeviceInfoSaga() {
  yield takeEvery(GET_DEVICE_INFO, fetchDeviceInfos);
  yield takeEvery(ADD_NEW_DEVICE_INFO, addNewDeviceInfo);
  yield takeEvery(GET_DEVICE_INFO_BY_ID, getDeviceInfoById);
  yield takeEvery(UPDATE_DEVICE_INFO, updateDeviceInfo);
  yield takeEvery(DELETE_DEVICE_INFO, deleteDeviceInfo);
}

export default DeviceInfoSaga;

export { getDeliveryAgenciesRequest, sendMessageToDeviceRequest};
