import { useEffect, useState } from 'react';

const useIdleTimer = (timeout = 60 * 1000) => {
  // const navigate = useNavigate();
  const [lastActivity, setLastActivity] = useState(Date.now());

  // Function to handle user inactivity
  const handleInactivity = () => {
    const now = Date.now();
    const timeSinceLastActivity = now - lastActivity;

    if (timeSinceLastActivity >= timeout) {
      // Log the user out (clear token and redirect)
      console.log('User inactive for 5 minutes. Logging out...');
      localStorage.removeItem('token');
      // navigate('/login'); // Redirect to login page
      window.location.href = '/login';
    }
  };

  useEffect(() => {
    const events = ['mousemove', 'keydown', 'click', 'scroll'];

    // Reset the inactivity timer on any of these events
    const resetTimer = () => setLastActivity(Date.now());

    // Add event listeners for user interactions
    events.forEach((event) => window.addEventListener(event, resetTimer));

    const inactivityCheck = setInterval(handleInactivity, 1000); // Check every second

    // Cleanup event listeners and timer on component unmount
    return () => {
      events.forEach((event) => window.removeEventListener(event, resetTimer));
      clearInterval(inactivityCheck);
    };
  }, [lastActivity, timeout]);

  return null;
};


const IdleTimerWrapper = ({ timeout }) => {
  useIdleTimer(timeout);
  return null; // No UI to render, just invoking the hook
};

export default IdleTimerWrapper;
