import {
  SALE_ORDER_INVOICE_ADD_NEW,
  SALE_ORDER_INVOICE_ADD_NEW_ERROR,
  SALE_ORDER_INVOICE_ADD_NEW_SUCCESS,
  SALE_ORDER_INVOICE_CLEAR_NOTIFY,
  SALE_ORDER_INVOICE_DETAIL_GET_BY_ID,
  SALE_ORDER_INVOICE_DETAIL_GET_BY_ID_SUCCESS,
  SALE_ORDER_INVOICE_GET_BY_ID,
  SALE_ORDER_INVOICE_GET_BY_ID_ERROR,
  SALE_ORDER_INVOICE_GET_BY_ID_SUCCESS,
  SALE_ORDER_INVOICE_GET_PAGING,
  SALE_ORDER_INVOICE_GET_PAGING_ERROR,
  SALE_ORDER_INVOICE_GET_PAGING_SUCCESS,
  SALE_ORDER_INVOICE_UPDATE,
  SALE_ORDER_INVOICE_UPDATE_ERROR,
  SALE_ORDER_INVOICE_UPDATE_SUCCESS,
} from './actionType';
import { SEVERITY } from "../../helpers/utils";
// item
const initialState = {
  item: null,
  items: [],
  loading: false,
  error: null,
  itemCount: 0,
  pageCount: 0,
  total: 0,
  draftCount: 0,
  invoicedCount: 0,
  paidCount: 0,
  unpaidCount: 0,
  refundCount: 0,
  cancelledCount: 0,
  
  invoicedInvoiceIds: [],
  paidInvoiceIds: [],
  unpaidInvoiceIds: [],
};

export default function initState(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SALE_ORDER_INVOICE_DETAIL_GET_BY_ID:
    case SALE_ORDER_INVOICE_ADD_NEW:
    case SALE_ORDER_INVOICE_GET_PAGING:
    case SALE_ORDER_INVOICE_UPDATE:
      return {
        ...state,
        loading: true,
        error: null,
        item: null,
      };
      
    case SALE_ORDER_INVOICE_GET_PAGING_SUCCESS:
      const { itemCount, pageCount, data , invoicedInvoiceIds, paidInvoiceIds, unpaidInvoiceIds,
        draftCount,
        invoicedCount,
        paidCount,
        unpaidCount,
        refundCount,
        cancelledCount,
      } = payload;
      console.log(data)
      return {
        ...state,
        loading: false,
        error: null,
        itemCount,
        pageCount,
        items: data,
        draftCount,
        invoicedCount,
        paidCount,
        unpaidCount,
        refundCount,
        cancelledCount,
        invoicedInvoiceIds,
        paidInvoiceIds,
        unpaidInvoiceIds,
      };

    case SALE_ORDER_INVOICE_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        // item: payload
      };

    case SALE_ORDER_INVOICE_DETAIL_GET_BY_ID_SUCCESS:
    case SALE_ORDER_INVOICE_GET_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        item: payload,
      };

    case SALE_ORDER_INVOICE_ADD_NEW_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        // item: payload,
        message: {
          title: "Invoice",
          text: "Invoice has been generated successfully.",
          severity: SEVERITY.SUCCESS,
        },
      };
      
    case SALE_ORDER_INVOICE_CLEAR_NOTIFY:
      return {
        ...state,
        message: null,
        error: null
      }

    case SALE_ORDER_INVOICE_ADD_NEW_ERROR:
    case SALE_ORDER_INVOICE_GET_PAGING_ERROR:
    case SALE_ORDER_INVOICE_GET_BY_ID_ERROR:
    case SALE_ORDER_INVOICE_UPDATE_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return { ...state };
  }
}
