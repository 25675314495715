import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, CardHeader, CardImg, CardSubtitle, CardTitle, Input } from "reactstrap";

const SaleOrderSelectionCard = props => {
    const { saleOrder, selected, onSelect, onRowCheck } = props;
    const { typeName, total, customerName, customerPhoneNumber, saleOrderProducts, orderNo, address } = saleOrder;
  let productImage = null;

  if (saleOrderProducts.length > 0 && saleOrderProducts[0].productImages) {
    const { productImages } = saleOrderProducts[0];
    productImage = productImages.length > 0 ? productImages[0] : null;
  }
  
    const handleRowCheck = (e) => {
      const { checked } = e.target;
      onRowCheck(saleOrder.id, checked);
    };

    return (
      <Card className={"clickable mb-1 border " + (selected ? 'border-primary' : '')}
            onClick={() => onSelect(saleOrder)}>
        <CardHeader className={"bg-transparent border-bottom p-0 d-flex"}>
          <div className={"d-flex justify-content-center align-items-center"} style={{ width: "40px" }}>
             <Input
                name={`check_${orderNo}`} 
                onClick={handleRowCheck}
                checked={selected} 
                type="checkbox"
                readOnly
              />
          </div>
          <div className={"d-flex justify-content-center"} style={{ width: "100px" }}>
            {productImage ? (
                <CardImg
                    style={{ width: "90px", height: "68px", objectFit: "contain" }}
                    className={"p-1"}
                    src={productImage.url}
                    alt={productImage.fileName}
                />
            ) : (
                <div
                    style={{
                      width: "100px",
                      height: "100px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                >
                  <div className="avatar-order">
                    <i className="fas fa-solid fa-cube text-primary"></i>
                  </div>
                </div>
            )}
          </div>
          <div className={"flex-grow-1"}>
            <CardBody className={"ps-0 py-2"}>
              <CardTitle className="d-flex justify-content-between">
                <div className="d-flex">
                  <span className="text-primary me-2">Order No:</span>
                  <span className="text-primary">{orderNo}</span>
                </div>
                <span className="text-primary">${total}</span>
              </CardTitle>
              <CardSubtitle className="d-flex align-items-center">
                <div className="me-auto">
                  <span className="me-2 text-muted">Customer:</span>
                  <span>{customerName}</span>
                </div>
                {
                  address &&
                  <div className="mx-auto">
                    <span className="text-muted">Delivery Address:</span>
                    <span className="ms-2">{address.name}</span>
                  </div>
                }
              </CardSubtitle>
              <CardSubtitle>
                <span className={ "me-2 text-muted" }>Phone Number:</span>
                <span className={ "ms-2" }>{ customerPhoneNumber }</span>
              </CardSubtitle>
              <CardSubtitle>
                <span className={ "me-2 text-muted" }>Quantity:</span>
                <span className={ "ms-2" }>{ saleOrderProducts.length }</span>
              </CardSubtitle>
            </CardBody>
          </div>
        </CardHeader>
      </Card>
    );
  };
  

  SaleOrderSelectionCard.propTypes = {
    saleOrder: PropTypes.shape({
      customerName: PropTypes.string,
      orderNo: PropTypes.string, // Ensure orderNo is listed here
      productImages: PropTypes.array,
      paymentStatusName: PropTypes.string,
    }).isRequired,
    selected: PropTypes.bool,
    onSelect: PropTypes.func.isRequired,
    onRowCheck: PropTypes.func.isRequired,
  };
  

export default SaleOrderSelectionCard;