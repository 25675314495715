import {
  GET_PRODUCT_REPORT,
  GET_PRODUCT_REPORT_SUCCESS,
  GET_PRODUCT_REPORT_FAIL,
  PRODUCT_REPORT_CLEAR_NOTIFY,
} from "./actionTypes";

// ProductReportt
export const getProductReport = id => ({
  type: GET_PRODUCT_REPORT,
  payload: id,
});

export const getProductReporttSuccess = payload => ({
  type: GET_PRODUCT_REPORT_SUCCESS,
  payload,
});

export const getProductReporttFail = error => ({
  type: GET_PRODUCT_REPORT_FAIL,
  payload: error,
});

export const clearProductReporttNotify = () => ({
  type: PRODUCT_REPORT_CLEAR_NOTIFY,
});
