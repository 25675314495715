import Breadcrumbs from "components/Common/Breadcrumb";
import React, { useCallback, useEffect, useState } from "react";
import { MetaTags } from "react-meta-tags";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  Container,
  Input,
  Row,
} from "reactstrap";

import {
  getPagingWallet,
  addWallet,
  deleteWallet,
  toggleStatus
} from "store/actions";

import ConfirmDeleteModal from "components/Common/ConfirmDeleteModal";
import { debounce } from "lodash";
import ModalForm from "containers/wallet/ModalForm";
import WalletTable from "containers/wallet/WalletTable";
import UserSelect from "containers/user/UserSelect";
import WalletDisableModalForm from "containers/wallet/WalletDisableModalForm";

const WalletList = ({
  getPagingWallet,
  addWallet,
  deleteWallet,
  wallet: { items, loading, itemCount, pageCount },
  toggleStatus,
  history,
}) => {
  const [id, setId] = useState(null);
  const [term, setTerm] = useState('');
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [modalAddIsOpen, setModalAddIsOpen] = useState(false);
  const [modalConfirmDeleteIsOpen, setModalConfirmDeleteIsOpen] = useState(false);
  const [modalToggleIsOpen, setModalToggleIsOpen] = useState(false);
  const [userId, setUserId] = useState(null);
  const [showDisabled, setShowDisabled] = useState(false);

  const walletItem = items.find((item) => item.id === id); 
  const isDisabledParam = showDisabled ? true : null;

  const debouncedFetchData = useCallback(debounce(term => {
    setTerm(term);
  }, 500), []);

  useEffect(() => {
    getPagingWallet({ 
      page, 
      pageSize, 
      term, 
      userId: userId ? userId.value : null,
      isDisabled: isDisabledParam, 
    });
  }, [getPagingWallet, page, pageSize, term, userId, showDisabled]);

  const handleOnEdit = (id) => {
    history.push(`/wallet/${id}`);
  };

  const handleOnAddNew = () => {
    setModalAddIsOpen(true);
  };

  const handleSubmit = (data) => {
    addWallet({ data, queryParams: {} });
    setModalAddIsOpen(false);
  };

  const handleConfirmDelete = (id) => {
    setId(id);
    setModalConfirmDeleteIsOpen(true);
  };

  const handleToggle = (id) => {
    const selectedWallet = items.find(item => item.id === id);
    if (selectedWallet) {
      setId(id); 
      setModalToggleIsOpen(true);
    } else {
      console.error("No wallet found for ID:", id);
    }
  };

  const handleDelete = () => {
    deleteWallet({ data: id, queryParams: {} }); 
    setModalConfirmDeleteIsOpen(false);
  };

  const handleSubmitDisable = () => {
    const walletItem = items.find(item => item.id === id);
  
    if (walletItem) {
      const newToggleStatus = !walletItem.isDisabled;
      toggleStatus({ id: walletItem.id, toggle: newToggleStatus }); 
      setModalToggleIsOpen(false);
    } else {
      console.error("No wallet:", id);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Wallet | CR TechSoft</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Wallet" breadcrumbItem="Wallet List" />
          <Row>
            <Col md={12}>
              <Card className="mb-1 rounded-3">
                <CardHeader className="bg-transparent border pb-0 pt-3">
                  <Row className="g-1">
                    <Col md={2}>
                      <div className="search-box me-2 mb-2 d-inline-block">
                        <div className="position-relative">
                          <Input
                            type="text"
                            placeholder="Quick Search ..."
                            onChange={(e) => debouncedFetchData(e.target.value)}
                          />
                          <i className="fas fa-search search-icon" />
                        </div>
                      </div>
                    </Col>
                    <Col md={4}>
                      <UserSelect
                          id="driver-select"
                          name="userId"
                          value={userId}
                          roleNames={[]}
                          onChange={option => {
                            setUserId(option);
                          }}
                          placeholder="Select User"
                          className="mb-0"
                          classNamePrefix="select2-selection"
                      />
                    </Col>
                    <Col>
                      <div className="mt-2">
                          <Input
                            type="checkbox"
                            id="show-disabled"
                            checked={showDisabled}
                            onChange={(e) => setShowDisabled(e.target.checked)}
                          />
                          <label className="ms-2" htmlFor="show-disabled">
                            Disabled?
                          </label>
                        </div>
                    </Col>
                    <Col md={4} className="text-sm-end">
                      <Button
                        type="button"
                        color="primary"
                        className="me-1"
                        onClick={handleOnAddNew}
                      >
                        <i className="fas fa-plus" /> Add New
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <Collapse isOpen={false}>
                  <CardBody className="pb-1"></CardBody>
                </Collapse>
              </Card>
            </Col>
            <Col md={12}>
              {loading ? (
                <div className="d-flex justify-content-center">
                  <div className="spinner-border text-primary m-1" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              ) : (
                items && (
                  <WalletTable
                    items={items}
                    itemCount={itemCount}
                    page={page}
                    totalPage={pageCount}
                    loading={loading}
                    defaultPageSize={pageSize}
                    onEdit={handleOnEdit}
                    onConfirmDelete={handleConfirmDelete}
                    onToggle={handleToggle}
                    onChangePage={(page) => setPage(page)}
                    onPageSizeChange={(pageSize) => {
                      setPage(1);
                      setPageSize(pageSize);
                    }}
                  />
                )
              )}
              <WalletDisableModalForm
                title="Confirm"
                isOpen={modalToggleIsOpen}
                toggle={() => setModalToggleIsOpen(!modalToggleIsOpen)}
                onSubmit={() => handleSubmitDisable()}
                isDisabled={walletItem ? walletItem.isDisabled : null}
              />

              <ModalForm
                title="Add Wallet"
                isOpen={modalAddIsOpen}
                toggle={() => setModalAddIsOpen(!modalAddIsOpen)}
                onSubmit={handleSubmit}
              />
              <ConfirmDeleteModal
                title="Confirm Delete"
                isOpen={modalConfirmDeleteIsOpen}
                toggle={() => setModalConfirmDeleteIsOpen(!modalConfirmDeleteIsOpen)}
                onSubmit={handleDelete}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

WalletList.propTypes = {
  wallet: PropTypes.object.isRequired,
  getPagingWallet: PropTypes.func.isRequired,
  addWallet: PropTypes.func.isRequired,
  toggleStatus: PropTypes.func.isRequired,
  deleteWallet: PropTypes.func.isRequired,
};

const mapStateToProps = ({ wallet }) => ({
  wallet,
});

export default withRouter(
  connect(mapStateToProps, {
    getPagingWallet,
    addWallet,
    toggleStatus,
    deleteWallet,
  })(WalletList)
);
