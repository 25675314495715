import CustomPagination from "components/Common/CustomPagination";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import React, { useCallback, useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Input,
  Label,
} from "reactstrap";
import ProductTransactionCard from "containers/product-transaction/ProductTransactionCard";
import {
  getPagingProductTransaction,
  addProductTransaction,
  getProductTransactionById,
  updateProductTransaction,
  deleteProductTransaction,
} from 'store/actions';
import { debounce } from "lodash";

const ProductTransactionTab = props => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [term, setTerm] = useState("");

  const {
    getPagingProductTransaction,
    addProductTransaction,
    updateProductTransaction,
    productTransaction,
    productId,
  } = props;

  const {
    items,
    itemCount,
    pageCount,
    loading,
  } = productTransaction;

  useEffect(() => {
    getPagingProductTransaction({ productId, page, pageSize,term });
  }, [page, pageSize, term]);

  const handleOnPageChange = page => {
    setPage(page);
  };

  const handleOnPageSizeChange = pageSize => {
    setPage(1);
    setPageSize(pageSize);
  };

  const debouncedFetchData = useCallback(
    debounce((term) => {
      setTerm(term);
    }, 1000),
    []
  );

  return (
    <div>
      <Card className="mb-1 border-primary shadow-sm">
        <CardHeader className="bg-transparent border-bottom d-flex justify-content-between align-items-center">
          <div className="search-box d-inline-block">
            <div className="position-relative">
              <Input
                type="text"
                placeholder="Quick Search ..."
                onChange={e => {
                  debouncedFetchData(e.target.value);
                }}
              />
              <i className="fas fa-search search-icon"/>
            </div>
          </div>
        </CardHeader>
      </Card>
   
      {loading ? (
        <div className="d-flex justify-content-center">
          <div className="spinner-border text-primary m-1" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      ) : (
        items.map((item, index) => (
          <ProductTransactionCard
            key={index}
            item={item}
          />
        ))
      )} 
      <Card className="border-secondary shadow-sm">
        <CardBody>
          <CustomPagination
            itemCount={itemCount}
            currentPage={page}
            totalPage={pageCount}
            defaultPageSize={pageSize}
            pageSizeOptions={[5, 10, 20, 30, 40, 50, 100]}
            onChangePage={i => setPage(i)}
            onPageSizeChange={size => {
              setPage(1);
              setPageSize(size);
            }}
          />
        </CardBody>
      </Card>
    </div>
  );
};

ProductTransactionTab.propTypes = {
  productId: PropTypes.string,
  productTransaction: PropTypes.shape({
    items: PropTypes.array,
    item: PropTypes.object,
    itemCount: PropTypes.number,
    pageCount: PropTypes.number,
    loading: PropTypes.bool,
  }),
  getPagingProductTransaction: PropTypes.func,
  addProductTransaction: PropTypes.func,
  getProductTransactionById: PropTypes.func,
  updateProductTransaction: PropTypes.func,
  deleteProductTransaction: PropTypes.func,
  clearProductReviewError: PropTypes.func,
};

ProductTransactionTab.defaultProps = {
  productId: '',
  productTransaction: {
    items: [],
    item: {},
    itemCount: 0,
    pageCount: 0,
    loading: false,
  },
  getPagingProductTransaction: () => {},
  addProductTransaction: () => {},
  getProductTransactionById: () => {},
  updateProductTransaction: () => {},
  deleteProductTransaction: () => {},
  clearProductReviewError: () => {},
};

const mapStateToProps = state => ({
  productTransaction: state.productTransaction,
});

export default withRouter(
  connect(mapStateToProps, {
    getPagingProductTransaction,
    addProductTransaction,
    getProductTransactionById,
    updateProductTransaction,
    deleteProductTransaction,
  })(ProductTransactionTab)
);
