import { call, put, takeEvery } from "redux-saga/effects";
import { GET_PRODUCT_REPORT } from "./actionTypes";
import { get, post } from "../../helpers/api_helper";
import { getProductReporttSuccess, getProductReporttFail } from "./actions";

const getProductReportRequest = query =>
  post(`/api/Product/productReport`, query);

function* getProductReport({ payload }) {
  try {
    const response = yield call(getProductReportRequest, payload);

    yield put(getProductReporttSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getProductReporttFail(error));
  }
}

function* ProductReportSaga() {
  yield takeEvery(GET_PRODUCT_REPORT, getProductReport);
}

export default ProductReportSaga;
