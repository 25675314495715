import FileSaver from "file-saver";
import { postFile } from "helpers/api_helper";

const exportToExcelRequest = async (query, fileName) => {
  return await postFile(`/api/SaleOrderInvoice/exportInvoiceDetailsToExcel`, query, {
    responseType: "blob",
  }).then(res => {
    if (res) {
      const { data } = res;
      FileSaver.saveAs(new Blob([data]), fileName);
    }
  });
};

export const saleOrderInvoiceReportService = {
  exportToExcelRequest,
};
