import { get, post, del, put as update } from "helpers/api_helper";

const getPagingRequest = async params => {
  return await get("/api/Address/paging", {
    params,
  });
};

const createRequest = async data => {
  return await post("/api/Address", data);
};

const getByIdRequest = async id => {
  return await get(`/api/Address/${id}`);
};

export const addressService = {
  getPagingRequest,
  createRequest,
  getByIdRequest,
};
