import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import NumberFormat from "react-number-format"
import Select from "react-select";

const SaleOrderProductRow = props => {
  const { item, onChange, onDelete, isSubmitted, isDisabled } = props
  const [ isEditable, setIsEditable ] = useState(!isDisabled)

  const handleCheckboxChange = (e) => {
    const { checked } = e.target
    onChange({
      ...item,
      isSelected: checked
    })
  }

  const handleSelectChange = (valueType, actionMeta) => {
    const { name } = actionMeta
    onChange({
      ...item,
      [name]: valueType
    })
  }
  
  return (
      <>
        <tr 
        onDoubleClick={ () => {
          if(!isDisabled){
            setIsEditable(!isEditable)
          }
         }}
        >
          <td className={ "input text-center pt-2" }>
            <input
                type="checkbox"
                className="form-check-input"
                checked={ item.isSelected }
                readOnly={ true }
                onChange={ handleCheckboxChange }
            />
          </td>
          <td>
            <div className="d-flex justify-content-center">
              {
                (() => {
                  let productImage = item.productImages.length > 0 ? item.productImages[0] : null;
                  return productImage ?
                    <img style={{ width: "80px", height: "50px", objectFit: "contain" }} src={productImage.url} alt="Product" />
                    :
                    <div className="avatar-order">
                      <i className="fas fa-solid fa-cube text-primary"></i>
                    </div>
                })()
              }
            </div>
          </td>
          <td>
            <>{ item.label }</>
          </td>
          <td className={isEditable ? 'input' : 'text-sm-end'} style={{ width: '250px' }}>
            {
              isEditable ?
                <>
                  <NumberFormat
                    name="price"
                    value={item.price !== null && item.price !== undefined ? item.price : ""} 
                    className={
                      "form-control text-end " +
                      (isSubmitted && (item.price === null || item.price === 0) ? "is-invalid" : "")
                    }
                    thousandSeparator={true}
                    decimalScale={2} 
                    fixedDecimalScale={true} 
                    allowEmptyFormatting={true}
                    onValueChange={values => {
                      const price = values.floatValue || null; 
                      const total = (price || 0) * (item.quantity || 0); 
                      onChange({
                        ...item,
                        price, 
                        total 
                      });
                    }}
                  />
                </>
                :
                <div className={isSubmitted && (item.price === null || item.price === 0) ? ' is-invalid' : ''}>
                  {item.price !== null && item.price !== undefined ? item.price.toFixed(2) : "0.00"}
                </div>
            }
            {isSubmitted && (item.price === null || item.price === 0) && (
              <div className="invalid-feedback-custom">
                Price is required.
              </div>
            )}
          </td>
          <td className={ isEditable ? 'input' : 'text-sm-end' } style={{ width: '250px' }}>
            {
              isEditable ?
                  <>
                    <NumberFormat
                        name="price"
                        value={ item.quantity }
                        className={
                            "form-control text-end " +
                            (isSubmitted && item.quantity === 0 ? "is-invalid" : "")
                        }
                        thousandSeparator={ true }
                        fixedDecimalScale={ false }
                        onValueChange={ values => {
                          values.floatValue = parseFloat(values.value)
                          let total = values.floatValue * item.price;
                          onChange({
                            ...item,
                            quantity: values.floatValue,
                            total
                          })
                        } }
                    />
                  </>
                  :
                  <div className={ isSubmitted && item.quantity === 0 ? ' is-invalid' : '' }>{ item.quantity }</div>
            }
            { isSubmitted && item.quantity === 0 && (
                <div className="invalid-feedback-custom">
                  Quantity is required.
                </div>
            ) }
          </td>
          <td className={ isEditable ? 'input' : 'text-sm-end' } style={{ width: '250px' }}>
            {
              isEditable ? (
                <Select
                  name="uomId"
                  value={item.uomId}
                  onChange={ handleSelectChange }
                  className={isSubmitted && !item.uomId ? "is-invalid" : ''}
                  options={item.uomOptions}
                  classNamePrefix="select2-selection"
                  isClearable={true} 
                  placeholder={"Select..."} 
                  noOptionsMessage={() =>
                    item.uomOptions.length === 0
                  }
                />
            ) : (
              <div className={isSubmitted && !item.uomId ? "is-invalid" : ''}>
                {item.uomId?.label} 
              </div>
            )}
            { isSubmitted && !item.uomId &&
                <div className="invalid-feedback-custom">
                  Unit of Measurement is required.
                </div>
            }

          </td>
          <td className={ isEditable ? 'input' : 'text-sm-end' }>
            {
              isEditable ?
                  <>
                    <NumberFormat
                        name="quantity"
                        value={ item.discount }
                        className={
                          "form-control text-end "
                        }
                        thousandSeparator={ true }
                        fixedDecimalScale={ false }
                        onValueChange={ values => {
                          values.floatValue = parseFloat(values.value)
                          onChange({
                            ...item,
                            discount: values.floatValue
                          })
                        } }
                    />
                  </>
                  :
                  <div>{ item.discount }</div>
            }
          </td>
          <td className={ 'text-sm-end' }>
            <NumberFormat
                name="total"
                value={ item.total.toFixed(2) }
                displayType={"text"}
                thousandSeparator={ true }
                fixedDecimalScale={ false }
                // onValueChange={ values => {
                //   values.floatValue = parseFloat(values.value)
                //   onChange({
                //     ...item,
                //     discount: values.floatValue
                //   })
                // } }
            />
            
          </td>
          <td className={ "input text-center" } style={{ width: '120px' }}>
            <div className="d-flex gap-3 justify-content-center">
              {!isDisabled && (
              <Link className="text-primary" to="#">
                <i
                    className={ "font-size-18 mdi " + (isEditable ? 'mdi-eye' : 'mdi-pencil') }
                    id="edittooltip"
                    
                    onClick={ () => {
                        setIsEditable(!isEditable)
                    } }
                ></i>
              </Link>
              )}
               {!isDisabled && (
              <Link className="text-danger" to="#">
                <i
                    className="mdi mdi-delete font-size-18"
                    id="deletetooltip"
                    onClick={ () => {
                        onDelete(item)
                    } }
                ></i>
              </Link> 
               )}
            </div>
          </td>
        </tr>
      </>
  )
}

SaleOrderProductRow.propTypes = {
  item: PropTypes.object,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
  isSubmitted: PropTypes.bool
}

export default SaleOrderProductRow